﻿@import "_variables.scss";
@import "_mixins.scss";

/* DATE PICKER */
.ui-datepicker {
    background: $brand-gray-25;
    padding: 0;

    .ui-datepicker-header {
        background: $brand-blue-75;
        color: $white;
        border-bottom: none;

        .ui-icon {
            background-image: none;
            opacity: 0;
        }
    }

    .ui-datepicker-title {
        margin: 0 60px;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        width: 30px !important;
        line-height: 40px;
        top: 1px !important;
        height: 30px !important;
        font-size: 20px;
        font-family: Arial, sans-serif;
        padding: 0 5px;
        white-space: nowrap;
        cursor: pointer;
        color: $white;
    }

    .ui-datepicker-prev {
        left: 4px !important;
        text-align: left;

        &::after {
            content: '➞';
            position: absolute;
            bottom: -6px;
            right: 4px;
            transform: rotate(180deg);
        }
    }

    .ui-datepicker-next {
        right: 4px !important;
        text-align: right;

        &::after {
            content: '➞';
            position: absolute;
            bottom: -6px;
            left: 4px;
        }
    }

    .ui-datepicker-prev span,
    .ui-datepicker-next span {
        display: none;
    }

    a {
        color: $text-color;
        text-decoration: none;
    }

    table {
        margin: 2px;

        thead {
            display: none;
        }

        td {
            padding: 1px;

            > a {
                text-align: center;
                line-height: 32px;
                border: solid 1px $brand-gray-25;

                &:hover,
                &.ui-state-active {
                    border-color: $black;
                    background: $brand-blue-75;
                    color: $white;
                }
            }
        }

        .ui-datepicker-today > a {
            font-weight: bold;
            background: $brand-red;
            color: $white;
        }
    }

    .ui-widget {
        font-family: inherit;
        font-size: inherit;
    }

    .ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus {
        border: 1px solid $brand-gray;
        font-weight: normal;
        color: $black;
    }
}

.ui-dialog {
    .ui-dialog-title {
        width: auto !important;
    }

    .ui-button-top {
        margin-right: 20px;
        float: right;
    }
}

.ui-dialog-browserwarning {
    .ui-dialog-content a {
        color: $link-color;
    }
}


/* AUTOCOMPLETE */
/* highlight results */
/* loading - the AJAX indicator */
.ui-autocomplete-loading {
    background: url(/Static/images/ajax_loader.gif) right 9px center no-repeat;
}

/* scroll results */
.ui-autocomplete {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px 5px 5px 0;
    position: absolute;
    background-color: $white;
    z-index: 100;
    list-style-type: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    width: 100%;
    max-width: 684px;

    @media (max-width: $screen-sm-max) {
        max-width: 508px !important;
    }

    span.hl_results {
        background-color: $brand-blue-25;
    }

    li {
        font-size: 16px;
        margin-bottom: 5px;
        padding-left: 20px !important;

        &:hover {
            background: $brand-gray-15;
        }

        > div.ui-state-active {
            background: $brand-gray-15 !important;
        }

        > div.ui-state-active {
            background: $brand-gray-15 !important;
            color: $text-color;
        }
    }
}

.ui-helper-hidden-accessible {
    display: none;
}


ul.ui-autocomplete li.ui-state-focus {
    background: $brand-gray-15 !important;
}

* html .ui-autocomplete {
    min-height: 500px;
}

.ui-dialog {
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    max-width: calc(100vw - 20px); //Fix for overflow
    .ui-dialog-content {
        padding: 1em !important;

        &#addremovelistproduct .list-group {
            margin-bottom: 0;

            label {
                display: block;
            }
        }

        .list-group {
            label {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .ui-dialog-titlebar {
        background-color: $white !important;
        border: none !important;
        border-bottom: 1px solid $brand-gray-45 !important;
    }
}

@media (max-width: $screen-sm-max) {
    .ui-widget.ui-widget-content {
        max-width: 100%;
    }
}

.ui-widget-overlay {
    background: rgba(50,50,50,0.3) !important;
    opacity: 1 !important;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        backdrop-filter: blur(3px);
    }
}

.no-close .ui-dialog-titlebar-close {
    display: none;
}