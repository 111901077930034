﻿@import "_variables.scss";
@import "_mixins.scss";

.list-group-item, .shopping-cart-item, .newslist {

    a.partial-buy-standard-variants {
        &:hover, &:focus {
            color: $white;
        }

        color: $white;
    }

    a {
        color: $text-color;

        &:hover, &:focus {
            color: $text-color;
            text-decoration: none;

            &.link-styling-list-group, .link-styling-list-group {
                color: $brand-blue-75;
                text-decoration: underline;
            }

            &.link-underline, .link-underline {
                text-decoration: underline;
            }
        }

        &.link-styling-list-group, .link-styling-list-group {
            color: $text-color;
            font-weight: bold;
            font-size: 18px;
            margin: 0;

            &.small {
                font-size: 16px;
                font-weight: 500;
            }
        }

        &.btn-primary, &.btn-secondary {
            color: $white;
        }
    }

    .price, .search-price {
        font-size: 18px;
        margin-top: 20px;

        strong {
            color: $brand-blue-75;
        }
    }

    &.link-list {
        padding: 0;

        a {
            display: block;
            padding: 13px 25px 12px 15px;

            &::after {
                content: "";
                width: 8px;
                height: 16px;
                background: url("/Static/images/icons/svg/SIS_Ikon_Karusell_hoger.svg") no-repeat center center;
                background-size: 8px 16px;
                position: absolute;
                top: 19px;
                right: 15px;
            }
        }
    }

    .link-styling-list-group a {
        color: $link-color;

        &:hover, &:focus {
            color: $brand-blue;
            text-decoration: underline;
        }
    }

    .search-favorite-container {
        position: absolute;
        top: 18px;
        font-size: 12px;
    }

    > .search-favorite-container {
        display: inline-block;
        position: unset;
        top: unset;
        right: unset;
        font-size: 12px;
        text-align: right;
        margin: 10px 0 5px 0;

        > label {
            margin-bottom: 0;
        }
    }

    @media (max-width: $screen-xs-max) {
        .search-favorite-container {
            position: initial;
            right: unset;
            top: unset;
            display: inline-block;
            margin-top: 10px;
        }
    }

    .custom-checkbox {
        input + .custom-checkbox.custom-checkbox--sm {
            border: 1px solid $brand-red;
            background-position: center !important;
        }
    }

    .list-group-icon {
        width: 10%;
        float: left;
    }

    .list-group-text {
        width: 70%;
        float: left;
    }

    .list-group-right {
        width: 20%;
        float: left;
    }
}

.standard-days-left-text {
    color: #808080;
}

.list-group-item {
    background: $brand-gray-15;
    @include clearfix();
    padding: 15px;

    @media (max-width: $screen-xs-max) {
        padding: 10px;
    }

    border: none;
    margin: 0;
    border-radius: 0 !important;

    .panel > .list-group > &,
    & + & {
        border-top: solid 1px $brand-gray-45;
    }

    > p:last-child {
        margin-bottom: 0;
    }
}

.list-group-heading {
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 20px;
    background-color: $brand-gray-25;
    border-top: solid 1px $brand-gray-45;
}

.list-group-year-heading {
    font-weight: bold;
    padding: 10px 20px;
    text-align: center;
    background: $brand-gray;
}

.theme-list-group {
    .list-group-item {
        border-color: $white;
    }
}

.list-group-item--expandable {
    padding: 0 !important;

    > .list-group-item--expandable-head {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        padding: 20px;
        @include caret();

        &:hover {
            text-decoration: underline;
        }

        &::after {
            position: absolute;
            bottom: 20px;
            right: 20px;
            border-width: 10px;
            opacity: 0.7;
        }

        &:hover::after {
            opacity: 1;
        }
    }

    > .list-group-item--expandable-body {
        display: none;
        background: $brand-gray-25;
        padding: 20px;
        box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.1);

        > p:last-child {
            margin-bottom: 0;
        }
    }

    &.list-group-item--expanded {
        > .list-group-item--expandable-head {
            @include caret(up);

            &::after {
                border-bottom-style: solid;
                border-top-style: none;
            }
        }

        > .list-group-item--expandable-body {
            display: block;
        }
    }
}

.item-body {
    display: block;
    overflow: hidden;
    font-size: 16px;

    > p:last-child {
        margin-bottom: 0;
    }

    .updateAccordionLabel {
        cursor: pointer;
    }
}
