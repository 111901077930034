﻿@import "_variables.scss";
@import "_mixins.scss";

.loadingIframe {
    background: #333 url('http://code.jquery.com/mobile/1.3.1/images/ajax-loader.gif') center center no-repeat;
}
.image-text {
    font-weight: normal;
    font-style: italic;
    margin: 10px;
}

.overlay-block {
    width: 100%;
    display:inline-block;
    &.center-button {
        display: grid;
        place-items: center;
    }
}

a.float-right.LightBoxPdf {
    margin-bottom: -30px;
}

.lightBoxLink {
    margin: 10px 0;
}

.tableContentButtonWrap {
    display: inline-block;
    width: 100%;
    margin: 0 0 30px;
}

.lightbox, .LightBoxPdf {
    cursor: pointer;

    + .lightbox-icon {
        content: "";
        width: 32px;
        height: 32px;
        background-size: 32px;
        background-repeat: no-repeat;
        opacity: 0.8;
        background-image: url('/static/images/icons/svg/SIS_Ikon_Zoom.svg');
        display: block;
        position: absolute;
        transition: all 0.2s;
        right: 0;
        background-color: #FFF;
        margin-top: 5px;
        cursor: pointer;

        + .image-text {
            margin-right: 35px;
        }
    }

    &:hover {
        + .lightbox-icon {
            opacity: 1;
        }
    }
}

.lightbox-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0,0,0,0.5);
    padding: 2.5vh;
    display: flex;
}

.lightbox-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    max-width: 95vw;
    max-height: 95vh;
    width: fit-content();
    height: fit-content();
    display: flex;
    flex-direction: column;
    padding: 20px 20px 10px 20px;
    box-shadow: 0 5px 5px rgba(0,0,0,.25);

    .lightbox-close {
        position: absolute;
        top: 0;
        right: 0;
        background: #FFF;
        border: solid 1px transparent;
        padding: 10px;
        line-height: .6;
        margin-top: -10px;
        margin-right: -10px;
        border-radius: 20px;

        &:hover {
            background: #eee;
            border-color: #aaa;
        }
    }

    img {
        align-self: center;
        max-height: calc(100vh - 200px);
    }

    .lightbox-text {
        text-align: center;
        font-style: italic;
        min-height: 33px;
    }

    a {
        text-align: right;
        padding-right: 25px;
        background: url('/static/images/icons/svg/SIS_Ikon_Ladda_ned.svg') no-repeat center right;
        background-size: 14px 18px;
        bottom: 20px;
        right: 20px;
    }
}
