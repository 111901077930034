﻿@import "_variables.scss";
@import "_mixins.scss";

.btn {
    white-space: normal;
}
.list-group-item {
    .btn {
            white-space: normal;
    }
}
.btn-line-height-sm {
    display: inline-block;
    padding: $padding-small-vertical 0;
    line-height: 25px;
}

.btn-gray {
    @include button-variant($white, $brand-gray, $brand-gray);
    padding: 8px 20px 6px;
    border-radius: 2em;
    font-size: 19px;
}

.btn-rounded {
    @include btn-rounded();
}

.btn-primary {
    @include btn-rounded();
    font-size: 16px;
    background-color: $btn-primary-bg;
    border-color: $btn-primary-bg;
    color: #FFF !important;
    transition: background-color 0.25s ease, border-color 0.25s ease;

    @media (prefers-reduced-motion) {
        transition: none;
    }

    &[disabled] {
        @include button-variant($white, $brand-gray, $brand-gray);
    }

    &.btn-sm {
        font-size: 15px;
    }

    &:hover {
        background-color: $brand-red-75;
        border-color: $brand-red-75;
    }
}
.btn-secondary {
    @include btn-rounded();
    font-size: 16px;
}

.btn-greyed-out {
    @include btn-rounded();
    background-color: $brand-gray;
    border-color: $brand-gray;
    color: white;
}

btn-secondary {
    @include btn-rounded();
    font-size: 16px;
    background-color: $btn-secondary-bg;
    border-color: $btn-secondary-bg;
    color: $white;
    transition: background-color 0.2s ease;

    @media (prefers-reduced-motion) {
        transition: none;
    }

    &[disabled] {
        @include button-variant($white, $brand-gray, $brand-gray);
    }

    &.btn-sm {
        font-size: 17px;
    }

    &:hover {
        background-color: $brand-blue-75;
        border-color: $brand-blue-75;
        color: $white;
    }

    &:focus,
    &:active {
        border-color: $brand-blue-45;
        background-color: $brand-blue-45;
        color: $white;
    }
}

.btn-dark {
    @include button-variant($white, $brand-blue-75, $brand-blue-75);
}

.btn-info {
    float: right;
    margin-left: 20px;
    line-height: 10px;
    border-radius: 3px;
    background-color: $link-color;
    border-color: $link-color;
    color: #FFF !important;

    &:hover, &:active, &:focus {
        background-color: $brand-blue;
    }

    @media(min-width: $screen-sm) {
        &.partial-buy-standard-variants {
            position: inherit;
            margin: 6px 0 0 4px;
        }
    }

    &.white {
        background: $brand-gray-25;
        color: $text-color;
    }

    @media (min-width: $screen-sm-min) {
        position: absolute;
        left: 100%;
        margin-left: 0;
    }
}

a.btn-excel::before {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 384 512" width="20" height="16"><path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z" class=""></path></svg>');
    margin-right: 4px;
    vertical-align: middle;
}

.btn-lib {
    color: $link-color;
}

.btn-light.disabled, .btn-light:disabled {
    border-color: $gray-400;
}

.btn-icon {
    &:hover {
        background-color: $brand-gray-75;
        border-color: $brand-gray-75;
    }
}

.btn-icon img {
    height: 24px;
    width: 24px;
}

.btn-list-group {
    @include btn-rounded();
    font-size: 16px;
    background-color: #eceded;
    border-color: black;
    color: black !important;
    transition: background-color 0.25s ease, border-color 0.25s ease;

    @media (prefers-reduced-motion) {
        transition: none;
    }

    &[disabled] {
        @include button-variant($white, $brand-gray, $brand-gray);
    }

    &.btn-sm {
        font-size: 15px;
    }

    &:hover {
        background-color: $brand-gray;
    }
}