﻿@import "_variables.scss";
@import "_mixins.scss";


$breadcrumb-padding-vertical: 8px;
$breadcrumb-padding-horizontal: 0;
$breadcrumb-bg: transparent;
$breadcrumb-color: $white;
$breadcrumb-active-color: $white;
$breadcrumb-separator: ">";

.breadcrumbs {
    background: $brand-red;
    font-size: 14px;
    margin-top: 0;

    .breadcrumb {
        margin: 0;
        color: $white;
        padding: 7px 0;

        a {
            color: $white;
        }

        > li + li:before {
            content: ">\00a0";
            padding: 0 5px;
            color: #fff;
        }
    }
}