﻿@import "_variables.scss";
@import "_mixins.scss";

/* ORDER HISTORY */
.order-history {
    color: $text-color;
    width: 100%;

    > thead > tr > th {
        font-size: 18px;
        padding: 10px;
        color: $white;
        background: $brand-red;

        &:first-child {
            padding-left: 20px;
        }

        &:last-child {
            padding-right: 20px;
        }
    }

    > tbody > tr > td {
        padding: 10px;

        &:first-child {
            padding-left: 20px;
        }

        &:last-child {
            padding-right: 20px;
        }
    }

    .order-item-caret {
        width: 1%;
        white-space: nowrap;
        @include caret();
    }

    .order-item {
        cursor: pointer;
        padding: 10px 0px;
        margin-bottom: 10px;
        border-bottom: solid 1px $brand-gray-75;

        > td {
            background: $brand-gray-75;
            border-bottom: solid 2px $brand-beige-25;
        }

        &:hover {
            > td {
                background: $brand-gray-45;
            }
        }

        &.order-item--expanded {
            & + .order-detail {
                display: table-row;
            }

            > td {
                background: $white;
                border-bottom: solid 1px $brand-gray-75;
            }

            .order-item-caret {
                @include caret(up);
            }
        }
    }


    .order-detail {
        display: none;

        > td {
            background: $white !important;
        }

        .order-item--expanded + & {
            display: table-row;
        }

        table {
            width: 100%;
            margin-bottom: 10px;
        }

        .order-detail-inner {
            font-size: 18px;
        }
    }
}