﻿@import "_variables.scss";
@import "_mixins.scss";

$perspektiv-border-radius: 10px;
$perspektiv-border-radius-small: 5px;
$perspektiv-border-radius-large: 40px;
$perspektiv-article-list-column-gap: 3%;
$perspektiv-icon-color-gray: invert(60%) sepia(0%) saturate(311%) hue-rotate(246deg) brightness(83%) contrast(76%);

.perspektiv {
    &-gradient {
        padding-top: 5px;
        //background: linear-gradient(to right, $brand-red, $brand-red, $brand-blue 80%);
        background: linear-gradient(to right, $brand-red, $brand-red, $brand-red 80%);

        @media (min-width:$screen-md-min) {
            border-radius: $perspektiv-border-radius;
        }
    }

    &-title {
        background-color: $white;
        padding: 10px 0 20px 0;

        @media (min-width:$screen-md-min) {
            background-color: $brand-gray-45;
            border-radius: $perspektiv-border-radius $perspektiv-border-radius 0 0;
            padding: 20px;

            &--stand-alone {
                padding: 17px 20px 15px 20px;
                border-radius: $perspektiv-border-radius-small;
            }
        }

        &-link {
            position: absolute;
            margin-top: 3px;
            margin-right: 20px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-weight: bold;
        }
    }

    &-date {
        line-height: 16px;
    }

    &-date, &-badge, &-headline, &-preamble {
        margin: 15px 0 0 0;
    }

    &-badge {
        border-radius: 5px;
        color: $white;
        font-size: 12px;
        background: $brand-blue;
        text-transform: uppercase;
        padding: 6px 10px 3px;
        vertical-align: middle;
        margin-right: 10px;
        white-space: nowrap;
        display: inline-flex;
        align-items: center;
        width: fit-content;
    }

    &-article-list {
        display: flex;
        flex-wrap: wrap;
        column-gap: $perspektiv-article-list-column-gap;
        justify-content: space-between;
    }

    &-article-teaser {
        $teaser: &;

        border-radius: $perspektiv-border-radius;
        width: 100%;

        @media (min-width:$screen-md-min) {
            padding-bottom: 0;
            margin-bottom: 50px;
            width: calc(33.33% - #{$perspektiv-article-list-column-gap});

            &:first-of-type {
                margin-top: -10px;
            }
        }

        &:nth-child(4n+1) {
            width: 100%;
            position: relative;

            @media (min-width:$screen-md-min) {
                #{$teaser}-content {
                    position: absolute;
                    bottom: 0;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    border-radius: $perspektiv-border-radius;
                    padding: 10px 10px 20px 10px;
                    color: $white;
                    backdrop-filter: brightness(50%);
                }

                #{$teaser}-title {
                    width: 100%;
                }

                #{$teaser}-preamble {
                    display: none;
                }
            }
        }

        &:not(:last-of-type):after {
            margin: 40px 0;
            border-bottom: 2px solid $text-color-lighter;
            content: '';
            display: flex;

            @media (min-width:$screen-md-min) {
                display: none;
            }
        }

        &:last-of-type:after {
            margin-bottom: 40px;
            content: '';
            display: flex;

            @media (min-width:$screen-md-min) {
                display: none;
            }
        }

        &-link {
            color: $text-color;

            &:hover {
                text-decoration: none;
            }
        }

        &-image {
            width: 100%;
            border-radius: $perspektiv-border-radius;
        }

        &-placeholder {
            display: none;
            width: 100%;
            border-radius: $perspektiv-border-radius;

            @media (min-width:$screen-md-min) {
                display: block;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &-badge-wrapper {
            @media (min-width:$screen-md-min) {
                order: -1;
            }
        }

        &-preamble {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }
    }

    &-top-content {
        margin-bottom: 20px;

        &-list {
            counter-reset: item;
            list-style-type: none;
            padding: 0 5px;
        }

        &-link {
            color: $text-color;

            &:hover {
                text-decoration: none;
            }
        }

        &-list-item {
            font-size: 20px;
            display: inline-block;
            width: 100%;
            border-bottom: 3px solid $brand-red;


            &:before {
                content: counter(item);
                counter-increment: item;
                font-size: 56px;
                padding-right: 10px;
            }

            &-inner {
                vertical-align: top;
                display: inline-block;
                margin-top: 12px;
            }
        }
    }

    &-toolbox {
        display: none;

        @media (min-width:$screen-md-min) {
            display: block;
        }

        &-nav {
            margin-top: 0;
            background-color: $white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: none;
            flex-wrap: nowrap;
            gap: 5px;
        }

        &-nav-item {
            background-color: $brand-gray-45;
            width: 100%;
            height: auto;
            border-radius: $perspektiv-border-radius-small;
        }

        &-nav-item-wrapper {
            border-radius: $perspektiv-border-radius-small;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
        }

        &-nav-item-image {
            margin-top: -5%;
            filter: $perspektiv-icon-color-gray;
        }

        &-nav-item-link {
            background-color: $brand-gray-45;
            position: relative;
            padding: 90% 0 0 0 !important;
            border-radius: $perspektiv-border-radius-small;

            &.active {
                background: $brand-gray-15 !important;
                border-color: transparent !important;
                border-radius: $perspektiv-border-radius-small $perspektiv-border-radius-small 0 0;
            }

            &:hover {
                background: $brand-gray-15 !important;
                border-color: transparent !important;
            }
        }

        &-tab {
            background-color: $brand-gray-15;
            padding: 20px;
            border-radius: 0 0 $perspektiv-border-radius-small $perspektiv-border-radius-small;
        }
    }

    &-notice {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        border-radius: $perspektiv-border-radius;
        box-shadow: 0 3px 5px 0px rgba(0,0,0,0.2);
        padding: 10px 10px 20px 10px;

        @media (min-width:$screen-md-min) {
            padding: 20px 20px 40px 20px;
        }

        &--expandable {
            cursor: pointer;

            &:hover {
                background: $brand-gray-15;
            }
        }

        &-list > * {
            margin-bottom: 1rem;
        }

        &-text-partial {
            list-style: none;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            pointer-events: auto;
        }

        &-text-full {
            display: none;
        }

        &-readmore {
            margin-bottom: 16px;
            display: block;
            text-align: center;
        }
    }

    &-notice-block {
        background-color: $brand-gray-15;
        border-radius: $perspektiv-border-radius-small;
        margin-bottom: 20px;

        &-title {
            background-color: $brand-gray-45;
            border-top-left-radius: $perspektiv-border-radius-small;
            border-top-right-radius: $perspektiv-border-radius-small;
            padding: 12px 15px 9px 15px;
            font-size: 18px;
        }

        &-item {
            padding: 10px 15px;

            &-title {
                margin: 0;
                line-height: 1.45;
                font-size: 16px;
                font-weight: bold;
            }

            &-divider {
                margin: 0;
                border-top: 2px solid $brand-gray-45;
                opacity: 1;
            }
        }

        &-link {
            display: inline-block;
            width: 100%;
            text-align: center;
            margin: 10px auto 20px auto;
        }
    }

    &-article {
        &-image {
            width: 100%;
            border-radius: $perspektiv-border-radius $perspektiv-border-radius 0 0;
        }

        &-content {
            border-radius: $perspektiv-border-radius;
            transform: translateY(-25px);
            background-color: $white;

            @media (min-width:$screen-md-min) {
                position: relative;
                padding: 40px 80px 50px 80px;
                border-radius: $perspektiv-border-radius-large;
                background-color: rgba(236, 237, 237, 0.5);
                backdrop-filter: blur(30px);
                transform: translateY(-50px);
                /*Firefox doensn't support backdrop-filter, so we set alpha to 1 */
                @supports (-moz-appearance:none) {
                    background-color: rgba(236, 237, 237, 1);
                }
            }

            &--no-image {
                border-top-left-radius: 0;
                border-top-right-radius: 0;

                @media (min-width:$screen-md-min) {
                    transform: translateY(-16px);
                }
            }
        }

        &-meta {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            transform: translateY(15px);

            @media (min-width:$screen-md-min) {
                transform: translateY(0);
            }
        }

        &-preamble {
            font-weight: bold;
            font-size: 19px;
        }
    }

    &-pre {
        font-family: "LL Akkurat",Arial,sans-serif;
        font-size: 16px;
        word-wrap: break-word;
        white-space: pre-wrap;
        margin-bottom: 10px;
    }

    &-menu {
        $this: &;

        position: fixed;
        inset: 0;
        width: 325px;
        z-index: 1000;
        background: $brand-beige-15;
        border-top-right-radius: $perspektiv-border-radius;
        border-bottom-right-radius: $perspektiv-border-radius;
        box-shadow: 3px 3px 2px 0px #e9e9e9;
        padding: 15px;
        transform: translateX(-101%);
        transition: transform 250ms ease-in-out;

        @media (min-width:$screen-md-min) {
            display: none;
        }

        &--open {
            transform: translateX(0%);

            #{$this}-tab {
                opacity: 0;
            }
        }

        &-tab {
            position: absolute;
            padding-top: 2px;
            border-top-left-radius: $perspektiv-border-radius-small;
            border-top-right-radius: $perspektiv-border-radius-small;
            transform: translate(65%, 41vh) rotate(90deg);
            right: 0;
            cursor: pointer;
            transition: opacity 250ms ease-in-out;
            height: 22px;

            &-inner {
                background: $brand-beige-15;
                font-size: 14px;
                font-weight: bold;
                padding: 0 25px;
                border-top-left-radius: $perspektiv-border-radius-small;
                border-top-right-radius: $perspektiv-border-radius-small;

                &:hover {
                    background: $brand-beige-25;
                }
            }
        }

        &-close {
            float: right;
            margin: 0;
            background: url(/Static/images/icons/svg/SIS_Ikon_Stang.svg) center no-repeat;
            border: none;
            width: 50px;
            height: 50px;
            background-size: 35px 35px;
        }
    }

    &-article-topic {
        position: relative;
        background: $brand-blue;
        border-radius: $perspektiv-border-radius-small;
        display: flex;
        flex-direction: row;
        height: 100%;

        &-link {
            &:hover {
                text-decoration: none;
            }
        }

        &-image {
            width: 50%;
            object-fit: cover;
            border-top-left-radius: $perspektiv-border-radius-small;
            border-bottom-left-radius: $perspektiv-border-radius-small;
        }

        &-text {
            margin-bottom: 0;
            font-weight: normal;
            color: $white;

            &-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &-arrow {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 10px;
        }
    }

    &-links {
        background-color: $brand-gray-15;
        border-radius: $perspektiv-border-radius-small;
        margin-bottom: 20px;
        padding-bottom: 10px;

        &-title {
            background-color: $brand-gray-45;
            border-top-left-radius: $perspektiv-border-radius-small;
            border-top-right-radius: $perspektiv-border-radius-small;
            padding: 12px 15px 9px 15px;
            font-size: 18px;
        }

        &-link {
            padding: 5px 15px 5px 15px;
            display: block;
        }
    }
}

#perspektivServiceSearch {
    margin-top: -10px;
    background: white;
    z-index: 1;
    margin-bottom: 20px;

    @media (min-width:$screen-md-min) {
        margin-top: -30px;
    }

    @media (min-width:$screen-lg-min) {
        position: absolute;
        right: 10px;
    }

    .perspektiv-search-tools {
        display: flex;
        position: relative;

        span {
            flex: 1;

            input {
                border: solid 1px $brand-gray-75;
                padding: 5px 5px 5px 30px;
                font-size: 16px;
            }

            &:before {
                content: "";
                position: absolute;
                background: url(/Static/images/icons/svg/SIS_Ikon_Sok.svg) no-repeat;
                width: 16px;
                height: 16px;
                top: 12px;
                left: 10px;
            }
        }

        button {
            background: $brand-red;
            padding: 7px 15px;
            border-radius: 0;
            color: #FFF;
            border-left: 0;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 1em;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;

            &:hover, &:focus {
                color: #FFF;
                opacity: 0.8;
            }
        }
    }
}
