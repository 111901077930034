﻿@import "_variables.scss";
@import "_mixins.scss";

.table-wrapper {
    overflow-x: auto;
    max-width:100%;
}
.tablesorter {
    width: 100%;

    th[role=columnheader]:not(.no-sort) {
        cursor: pointer;
    }

    th[role=columnheader]:not(.no-sort):after {
        content: '';
        float: right;
        margin-top: 7px;
        margin-right: -12px;
        border-width: 0 4px 4px;
        border-style: solid;
        border-color: #FFF transparent;
        -webkit-user-select: none;
        user-select: none;
        opacity: 0.4;
    }

    th[aria-sort=ascending]:not(.no-sort):after {
        border-bottom: none;
        border-width: 4px 4px 0;
    }

    th[aria-sort]:not(.no-sort):after {
        visibility: visible;
        opacity: 1;
    }

    th[role=columnheader]:not(.no-sort):hover:after {
        visibility: visible;
        opacity: 1;
    }
}


/* Job */
#TableJobs {
    padding: 0 40px 0 0;

    table {
        min-width: 100%;
        margin-top: 15px;

        td, #TableJobs table th {
            text-align: left;
            padding: 5px 25px 5px 0;
        }
    }

    caption {
        text-align: left;
        margin-bottom: 10px;
        display: none;
    }

    th a {
        text-decoration: none;
    }

    .Lst-BG1 td, #TableJobs .Lst-BG2 td, #TableJobs .Lst-Header th {
        border-bottom: 1px solid $brand-gray-75;
    }

    .Lst-ResNav {
        font-size: 10px;
        display: none;
    }
}

th#th2 {
    display: none;
}

td.Lst-Desc12, td.Lst-Desc22 {
    display: none;
}


.book-course-table {
    width: 100%;
    @include module-margin();

    th,
    td {
        font-size: 18px;
        padding: 10px;
    }

    th {
        font-weight: normal;
        background-color: #FFF;
    }

    thead th {
    }

    tbody td {
        background: $white;
        border-bottom: solid 2px $brand-gray-15;
    }

    td:last-child {
        white-space: nowrap;
        width: 1%;
    }
}

.book-course-table-remove {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-left: 10px;
    background: url('/Static/images/icons/svg/SIS_Ikon_Stang.svg') center center no-repeat;
    background-size: 20px 20px;
}

.table-container {
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 15px;

    table {
        margin-bottom: 0;
        margin-top: 0;
        border-radius: 5px;
        overflow: hidden;
    }
}

table p:only-child {
    margin: 0;
}

.table-default {
    font-family: $font-family-base !important;

    thead {
        tr {
            th {
                padding: 10px 20px 10px 5px !important;
                font-size: 14px;
                text-transform: uppercase;
                color: $white;
                background-color: $brand-red;
                border-bottom: $brand-gray-15 2px solid;
            }
        }
    }

    &.white tbody tr td {
        background-color: $brand-gray-15;
        border-bottom: solid 1px $brand-gray !important;
    }

    tbody {
        font-size: 18px;
        line-height: $line-height-base;

        tr {

            & > td {
                background-color: $white !important;

                &.primary {
                    background-color: $brand-gray-15 !important;
                }
            }
        }

        tr:hover > td {
            background-color: $brand-gray-45 !important;
        }
    }

    td {
        border-bottom: solid 2px $brand-gray-15 !important;
    }
}

.fullwidth-table {
    width: 100%;

    td {
        padding: 0 10px;
    }

    td:first-child {
        padding-left: 0;
    }

    td:last-child {
        padding-right: 0;
        width: 1%;
        white-space: nowrap;
    }

    @media (max-width: 400px) {
        font-size: 16px;

        td {
            padding: 0 5px;
        }
    }
}

.admin-setting-table {
    table-layout: fixed;

    tr {
        word-break: break-all;
    }

    #col-firstname {
        width: 13%;
    }

    #col-surname {
        width: 17%;
    }

    #col-email {
        width: 40%;
    }

    #col-setting {
        width: 30%;
    }

    #setting-dropdown {
        width: 100%;

        .option {
            overflow: hidden;
        }
    }
}

.smallest-possible-td {
    width: 0.1%;
    white-space: nowrap;
}

.checkout-table {
    width:1%;
    td {
        text-align: right;
        white-space:nowrap;
        padding:5px;
    }
}
