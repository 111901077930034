﻿@import "_variables.scss";
@import "_mixins.scss";

.color-white {
    color: $white;
}

.color-red {
    color: $brand-red;
}

.color-blue {
    color: $brand-blue;
}

.hidden {
    display: none;
}

.overflow--hidden {
    overflow: hidden;
}

.font-size-base {
    font-size: $font-size-base;
}

.no-js .visible-no-js {
    display: none;
}

.margin-top--100px {
    margin-top: 100px;
}

.margin-top--minus--37px {
    @media (min-width: $screen-sm-min) {
        margin-top: -37px;
    }
}

.color--text-color {
    color: $text-color;
}

.color--brand-primary {
    color: $brand-red;
}

.color--brand-danger {
    color: $brand-danger;
}

.font-weight--bold {
    font-weight: bold;
}

.cursor--pointer {
    cursor: pointer;
}

.line-through {
    text-decoration: line-through;
}

.font-smaller {
    font-size: 80%;
}

.font-larger {
    font-size: 130%;
}

.font-size--16px {
    font-size: 16px;
}

.font-size--18px {
    font-size: 18px;
}

.font-size--24px {
    font-size: 24px;
}

.no-border {
    border: none !important;
}

.padding-left--0--xs {
    @media (max-width: $screen-xs-max) {
        padding-left: 0 !important;
    }
}

.padding-left--0--sm {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-left: 0 !important;
    }
}

.module-margin {
    @include module-margin;
}

.module-margin-top {
    @include module-margin-top;
}

.module-margin-xs {
    @include module-margin-xs;
}
.relative {
    position: relative;
}
.nowrap{
    white-space:nowrap;
}
.gap-1 {
    gap: .25rem;
}
.gap-2 {
    gap: .5rem;
}
.gap-3 {
    gap: 1rem;
}
.gap-4 {
    gap: 1.5rem;
}

.fit-content {
    max-width: fit-content;
}