﻿@import "_variables.scss";
@import "_mixins.scss";

/* PANELS */
.panel {
    border: none;
    box-shadow: none;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;

    .panel-title {
        font-size: 18px;
        margin: 0;
    }

    .pane & {
        background: $white;
    }

    .list-group-item {
        font-size: 16px;
        border-top: solid 1px $brand-gray-75;

        .bold-time {
            font-weight: bold;   
            padding: 0 5px 0 0;
        }

        img {
            display: inline-block;
        }
    }

    > .expandable-area > .list-group .list-group-item,
    > .expandable-wrapper > .expandable-area > .list-group .list-group-item {
        border-width: 1px 0;
        border-radius: 0;
    }

    > .expandable-area > .list-group,
    > .expandable-wrapper > .expandable-area > .list-group {
        margin-bottom: 0;
    }
}

.panel-heading {
    padding: 12px 15px 9px;
    font-weight: bold;

    .pane & {
        background: $brand-gray-45;
        color: $text-color;
    }

    &:empty {
        padding: 0 !important;
        border-bottom: 1px solid $white;
    }

    small {
        font-weight: bold;
    }
}

.panel-body {
    font-size: 16px;
    line-height: 1.35;
    padding: 15px;
    background: $brand-gray-15;

    .pane & {
        background: $white;
        color: $text-color;
    }

    > .panel-sub {
        margin-left: -20px;
        margin-right: -20px;
    }

    > p:last-child {
        margin-bottom: 0;
    }

    ul {
        padding-left: 20px;
        line-height: 1.4;
    }

    > ul:last-child {
        margin-bottom: 0;
    }

    ol {
        padding-left: 0;
        list-style: inside inside;
    }
}
/* EXPANDABLE PANELS */
.panel--expandable {
    > .panel-heading {
        position: relative;
        display: block;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
        padding-right: 50px !important;
        @include caret();


        &:hover {
            background: $brand-gray-75;
        }

        &::after {
            opacity: 0.7;
        }

        &:hover::after {
            opacity: 1;
        }
    }

    &.panel-lg {
        > .panel-heading {
            @include caret();

            &::after {
                position: absolute;
                right: 20px;
                top: 24px;
            }
        }

        &.panel--expanded > .panel-heading {
            @include caret(up);
        }
    }

    &.panel--expanded > .panel-heading {
        @include caret(up);

        &::after {
            border-top: none;
        }
    }

    > .panel-body,
    > .list-group,
    > .expandable-wrapper,
    > table {
        display: none !important;
    }

    &.panel--expanded > .panel-body,
    &.panel--expanded > .list-group,
    &.panel--expanded > .expandable-wrapper,
    &.panel--expanded > table {
        display: block !important;
    }
}

.panel-text {
    background: transparent;

    > .panel-heading {
        color: $text-color;
        background: transparent;
        padding: 0;
        margin-bottom: 10px;
    }

    > img {
        padding-bottom: 20px;
    }

    > .panel-body {
        background: transparent;
    }
}

.panel-gray > .panel-heading {
    background: $brand-gray-45;
    color: $text-color;
    padding: 12px 15px 9px 15px;
    margin-bottom: 0;
}

.panel-primary {
    & > .panel-heading {
        background: $brand-red;
        border-color: $brand-red;
        color: #FFF;
    }

    .extend-abo-text {
        margin: 15px 0 -20px 0;
        float: right;
    }
}

.panel-secondary {
    & > .panel-heading {
        background: $brand-blue;
        border-color: $brand-blue;
        color: $white;
    }
}

.panel-sub {
    margin: 0;
    background: $brand-gray-15;

    > .panel-body {
        background: $brand-gray-25;
        box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.05);
        border-bottom: solid 1px $brand-gray-75;
    }

    &.panel--expandable:last-child > .panel-heading {
        border-bottom: none;
    }

    &.panel--expandable.panel--expanded:last-child > .panel-heading {
        border-bottom: solid 1px $brand-gray-75;
    }
}

.panel-lg {
    > .panel-heading {
        font-size: $font-size-h4;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    > .panel-body {
        padding: 15px;
        font-size: $font-size-base;

        > hr {
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    > .panel-body > .panel-sub,
    > .panel > .panel-body > .panel-sub {
        margin-left: -15px;
        margin-right: -15px;
    }

    .list-group-item {
        font-size: $font-size-base;
        padding-left: 20px;
        padding-right: 20px;
    }

    > .panel > .panel-heading,
    > .panel > .panel-body {
        padding-left: 20px;
        padding-right: 20px;
    }
}