﻿@import "_variables.scss";
@import "_mixins.scss";


.productlistblock {
    margin-bottom: 20px;

    @media (max-width: $screen-xs-min) {
        .margin-top--minus--37px {
            margin-top: 0;
        }
    }

    &.tag-span4 {
        .list-group-item {
            background: none;


            > a {
                display: block;
                font-size: 16px;
            }

            .link-styling-list-group {
                font-size: 16px;
            }
        }

        h2 {
            background: $brand-gray-45;
            color: $text-color;
            padding: 12px 20px 9px 20px;
            margin-bottom: 0;
            font-size: 18px;
        }

        .item-icon {
            img {
            }
        }

        .meta-row {
            .badge {
                display: none;
            }

            .item-designation {
                display: block;
            }
        }

        .item-body {
            overflow: auto;
        }

        .item-title, .item-price, .addtocart {
            display: none !important;
        }
    }
}

.teasersectionblock {
    .teaser-section-wide-format {
        .row {
            display: block;

            .col-sm-4 {
                width: 100%;
                margin-block: $grid-gutter-width;
            }
        }

        .image-teaser {
            a {
                display: flex;

                > img {
                    display: none;

                    @media (min-width: $screen-md-min) {
                        display: inline-block;
                        flex: 1;
                    }
                }

                .image-teaser-text {
                    flex: 1;
                    display: grid;
                    justify-content: flex-start;
                    align-content: center;
                    font-size: 20px;
                    text-align: left;
                }
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        margin-bottom: 20px;

        .row {
            .link-arrow {
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 12px;
                    right: 22px;
                    width: 7.8px;
                    height: 14.3px;
                    background-image: url(/Static/images/icons/svg/SIS_Ikon_Karusell_hoger_white_small.svg);
                    background-size: 7.8px 14.3px;
                }
            }
        }
    }

    @media (max-width: $screen-sm-min) {
        word-break: break-word;

        .h2 {
            font-size: 22px;
        }

        .image-teaser-text {
            font-size: 14px;
            padding: 17px 15px;
        }

        .col-sm-4 {
            padding: 0 5px;
        }

        .text-teaser {
            padding: 25px 15px;
            height: auto;

            p {
                font-size: 14px;
            }
        }
    }
}

.socialmediablock {
    .panel-heading {
        background-color: $brand-blue-75;
        border-color: $brand-blue-75;
    }
}
/* Startpage Redesign 2019 */
.teasersectionredesignblock, .bookcarouselblock {
    h2 {
        font-size: 32px;
    }

    > .gray-background {
        background-color: $brand-gray-15;
        padding: 60px 0 50px;
    }

    > .white-background {
        background: transparent;
        margin: 60px 0 50px;
    }

    @media (max-width: $screen-sm-min) {
        h2 {
            font-size: 28px;
        }

        > .gray-background {
            padding: 25px 0 20px;
        }


        > .white-background {
            margin: 25px 0 20px;
        }
    }
}

.teaser-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .theme-teaser.col-sm-4, .img-teaser.col-sm-4, .txt-teaser.col-sm-4 {
        box-sizing: border-box;
        padding: 0 10px;
        margin-bottom: 20px;
        position: relative;
        flex-grow: 1;
        display: flex;

        .text-top {
            padding: 25px 20px 20px;
        }

        .text-main {
            padding: 20px;
            word-break: break-word;

            &::after {
                content: "";
                position: absolute;
                bottom: 15px;
                right: 25px;
                width: 8px;
                height: 15px;
                background-image: url(/Static/images/icons/svg/SIS_Ikon_Karusell_hoger_white_small.svg);
                background-size: 8px 15px;
            }
        }

        p {
            padding-right: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            position: absolute;
            height: 100%;
            top: 0;
            left: 10px;
            background: rgba(0,0,0,0) 0 0 repeat;
        }
    }

    .theme-teaser.col-sm-4, .txt-teaser.col-sm-4 {
        > div {
            min-height: 350px;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    .theme-teaser.col-sm-4 {
        > div {
            position: relative;
        }

        .theme-teaser-background {
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
        }

        .text-top {
            flex: 1 0 auto;
            color: white;
            z-index: 0;

            h1 {
                font-size: 28px;
                margin-bottom: 5px;
            }
        }

        .text-main {
            background: linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), rgba(0,78,125,0.7);
            color: white;
            z-index: 0;
        }
    }

    .txt-teaser.col-sm-4 {
        display: flex;
        flex: 1 0 33.333333%;

        .text-top {
            flex: 1 1 auto;
        }

        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 0;
        }
    }

    .txt-teaser.col-sm-4.col-sm-8 {
        flex: 1 0 66.6666667%;
    }

    .img-teaser {
        &.col-sm-4 {
            display: flex;
            flex-direction: column;

            .imgbg {
                background-size: cover;
                background-position: center center;
                min-height: 206px; /* 16x9 ratio */
                flex: 1 1 auto;
            }
        }

        @media (min-width: $screen-sm-min) {
            &.col-sm-8 {
                flex: 1 0 66.66%;
            }

            &.col-sm-12 {
                max-width: 100%;
                flex: 1 0 100%;

                h3 {
                    font-size: 26px;
                }
            }

            &.col-sm-8, &.col-sm-12 {
                min-height: 370px;

                .text-main {
                    position: absolute;
                    width: 50%;
                    max-width: 400px;

                    p {
                        padding-right: 25px;
                    }

                    &::after, &::before {
                        content: "";
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: 38px;
                        height: 38px;
                    }

                    &::after {
                        background: rgba(255,255,255,.3);
                    }

                    &::before {
                        background: url('/Static/images/icons/svg/SIS_Ikon_Karusell_hoger_white.svg') 15px 13px no-repeat;
                        background-size: 8px;
                    }

                    &.bottom-left {
                        left: 35px;
                        bottom: 25px;
                    }

                    &.bottom-right {
                        right: 35px;
                        bottom: 25px;
                    }

                    &.top-left {
                        left: 35px;
                        top: 25px;
                    }

                    &.top-right {
                        right: 35px;
                        top: 25px;
                    }
                }
            }
        }
    }

    @media (max-width: $screen-md-min) {
        .col-sm-4 {
            h3 {
                font-size: 20px;
            }
        }
    }

    @media (max-width: $screen-sm-min) {
        .col-sm-4, col-sm-8 {
            flex: 1 1 100%;
        }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
        .col-sm-4, col-sm-8 {
            flex: 1 1 33.33333333%;
        }
    }

    @media (min-width: $screen-md-min) {
        .col-sm-8 {
            flex: 1 1 66.66666667%;
        }
    }

    .event-news-list {
        display: flex;
        margin-bottom: 20px;
        flex-grow: 1;

        .panel {
            background: $brand-gray-15;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            flex: 0 1 100%;
        }

        .bold-time {
            font-weight: bold;
        }

        .list-group {
            flex: 1 1 auto;
        }

        &.col-sm-4 p.calendar-preamble {
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 5px 0;
        }


        .item-body {
            word-break: break-word;
            position: relative;

            .meta-row .badge {
                white-space: normal;
            }

            .meta-row {
                font-family: $font-family-monospace;
                text-transform: capitalize;

                &.chev {
                    padding-left: 15px;
                    display: block;
                    margin-top: 10px;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 11px;
                        height: 13px;
                        margin-top: 3px;
                        left: 0;
                        background: url(/Static/images/icons/svg/SIS_Ikon_Karusell_hoger.svg) no-repeat;
                        background-position: 0 1px;
                        background-size: 10px 10px;
                    }
                }
            }
        }

        .list-group-item {

            .item-icon {
                background: url('/Static/images/icons/svg/SIS_Ikon_Kalender.svg') no-repeat center center/44px 61px;
                width: 44px;
                height: 61px;

                .day {
                    font-size: 13px;
                    margin-top: 12px;
                    height: 14px;
                }

                .date {
                    font-size: 18px;
                }

                .day, .date {
                    display: block;
                    font-weight: bold;
                    text-align: center;
                }
            }
        }

        .list-group-item:first-child .link-styling-list-group {
            font-size: 20px;
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        .txt-teaser.col-sm-4, .img-teaser.col-sm-4 {
            flex: 1 0 30%;
        }

        .txt-teaser.col-sm-4.col-sm-8, .img-teaser.col-sm-4.col-sm-8 {
            flex: 1 0 65%;
        }

        .img-teaser.col-sm-4.col-sm-12 {
            flex: 1 0 100%;
        }
    }
}

.article {
    .eventlistblock {
        .event-news-list .list-group-item {
            .item-icon {
                background: url('/Static/images/icons/svg/SIS_Ikon_Kalender.svg') no-repeat center center/44px 61px;
                width: 44px;
                height: 61px;

                .day {
                    font-size: 13px;
                    margin-top: 12px;
                    height: 14px;
                }

                .date {
                    font-size: 18px;
                }

                .day, .date {
                    display: block;
                    font-weight: bold;
                    text-align: center;
                }
            }
        }
    }
}

.heroredesignblock {
    margin-top: -20px;

    @media (min-width: $screen-md-min) {
        margin-top: -30px;
    }
}

#hero-block {
    padding-top: 10vh;
    padding-bottom: 10vh;
    position: relative;
    height: calc(100vh - 162px);
    max-height: 1200px;
    height: auto;
    width: 100%;
    animation: hbbFadeIn 0.35s 0.25s ease-in-out;

    @media (min-width: $screen-lg-min) {
        min-height: 500px;
    }

    .hero-block-shadow {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow-x: hidden;
        background: rgba(0,0,0,0);
        z-index: -1;
        -webkit-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;

        &.active {
            background: rgba(0,0,0,0.3);
            z-index: 0;
        }
    }

    .hero-block-background {
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
    }

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.2);
        z-index: 0;
        transition: all 0.2s ease-in-out;
    }

    .hero-intro {
        color: $white;
        text-align: center;

        h1 {
            font-size: 52px;
            margin-bottom: 0;
        }

        h2 {
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
            margin-top: 10px;
        }

        @media (max-width: $screen-sm-min) {
            padding: 0 20px;

            h1 {
                font-size: 30px;
            }

            h2 {
                font-size: 18px;
                line-height: 27px;
            }
        }
    }

    .hero-intro, .hero-search {
        animation: fadeUp 0.35s 0.25s ease-in-out both;
        z-index:10;
    }

    .search-module {
        display: flex;


        .search-container {
            display: flex;
            flex: 1;
            border-radius: 5px;
            box-shadow: 0 1px 3px 0 rgba(0,0, 0, .1);
            margin: 50px;

            @media (max-width: $screen-sm-max) {
                margin: 50px 0;
            }

            .search-opt {
                width: fit-content;
                background: $brand-gray-45;
                height: 46px;
                padding-left: 5px;
                padding-right: 0;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            .search-box {
                flex: 1;
                position: relative;
                padding-right: 25px;
                padding-left: 25px;
                height: 65px;
                font-size: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border: none;
            }

            @media screen and (max-width: $screen-sm-max) {
                .search-box::-webkit-input-placeholder { /* WebKit browsers */
                    color: transparent;
                }

                .search-box::-moz-placeholder { /* Mozilla Firefox 19+ */
                    color: transparent;
                }
            }

            .search-button {
                border: none;
                flex-basis: 80px;
                padding-top: 10px;
                padding-bottom: 8px;
                line-height: $line-height-base;
                font-size: $font-size-base;
                background: url('/Static/images/icons/svg/SIS_Ikon_Sok.svg') $brand-gray-45 no-repeat center center;
                background-size: 50%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;

                &:hover {
                    background-color: $brand-gray-15;
                }
            }
        }
    }


    .toolbox-cart {
        position: absolute;
        z-index: 1;
        background: white url(/Static/images/SIS_Ikon_Shopping.svg) center center no-repeat;
        background-size: 28px 28px;
        top: 0;
        right: 20px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;

        @media (min-width: 1170px) {
            right: calc(50% - 580px);
        }

        margin-right: 0;
        padding-left: 2px;
        border: 0;
        font-size: 14px;
        margin-top: 0;
    }
}


.hero-list-block {
    margin-bottom: 0;
    display: flex;
    flex: 0 1 100%;
    opacity: 0;
    transition: transform 0.4s ease, opacity 0.5s ease;

    @media (prefers-reduced-motion) {
        transition: none;
    }

    flex-direction: column;

    @media (max-width: $screen-sm-min) {
        transform: translateY(0px) !important;
        margin-bottom: 0 !important;
        margin-top: 20px !important;
        flex-direction: column;
    }

    @media (min-width: $screen-sm-min) {
        flex-direction: row;

        .hero-list {
            &:last-child {
                margin-bottom: 20px;
            }
        }
    }

    .hero-list {
        display: flex;
        flex-grow: 1;
        margin-bottom: 20px;

        .panel {
            margin-bottom: 0;
            background: $brand-gray-15;
            display: flex;
            flex-direction: column;
            flex: 0 1 100%;
            overflow: hidden;

            .panel-heading {
                padding: 19px 15px 13px;
                font-size: 20px;
                position: relative;

                .btn-accordion {
                    display: block;
                    border: 0;
                    background: 0;
                    position: absolute;
                    width: 100%;
                    padding: 32px;
                    right: 0;
                    top: 0;
                    outline: 0;

                    &::after {
                        content: " ";
                        transition: transform 0.3s ease-in-out;

                        @media (prefers-reduced-motion) {
                            transition: none;
                        }

                        background: url(/Static/images/icons/svg/SIS_Ikon_Karuesell_upp_white.svg) center center/24px 24px no-repeat;
                        padding: 32px;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }

                    &.collapsed::after {
                        transform: rotate(-180deg);
                    }
                }
            }

            .collapse.show {
                display: block !important;
                visibility: visible;
            }

            @media (min-width: $screen-sm-min) {
                .panel-heading {
                    .btn-accordion {
                        display: none;
                    }
                }

                .collapse {
                    display: block !important;
                    visibility: visible !important;
                    height: unset !important;
                }
            }

            .list-group-item {
                padding: 0;

                a span {
                    padding: 20px 35px 20px 20px;
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &::after {
                        content: "";
                        position: absolute;
                        right: 20px;
                        bottom: 0;
                        top: 0;
                        margin-top: auto;
                        margin-bottom: auto;
                        width: 8px;
                        height: 15px;
                        background: url('/Static/images/icons/svg/SIS_Ikon_Karusell_hoger.svg') center center no-repeat;
                        background-size: 8px;
                    }

                    &:hover {
                        background: $brand-gray-25;
                    }
                }
            }
        }

        &::after {
            content: " ";
            position: absolute;
            height: 100%;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            top: 0;
            box-shadow: 0 48px 96px 0 rgba(25, 30, 55, 0.03),0px 24px 48px 0 rgba(25, 30, 55, 0.03),0px 12px 24px 0 rgba(25, 30, 55, 0.03),0px 6px 12px 0 rgba(25, 30, 55, 0.03),0px 3px 6px 0 rgba(25, 30, 55, 0.03);
            opacity: 0.75;
            z-index: -1;
        }

        @media (max-width: $screen-sm-max) {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.herospaceblock {
    position: relative;
    top: -20px;
    overflow: hidden;

    @media (min-width: $screen-md-min) {
        top: -30px;
    }

    > h1 {
        margin-top: 20px;
    }
}

.mymessageblock {
    display: block;

    &:hover, &:active {
        text-decoration: none;
    }
}


.text-teaser {
    display: block;
    padding: 20px 15px 20px 15px;
    color: $white;
    height: 100%;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $white;

        p {
            text-decoration: underline;
        }
    }

    strong {
        display: block;
        font-size: 32px;
        line-height: 1;
        font-weight: normal;
        margin-bottom: 20px;
    }

    em {
        display: block;
        font-style: normal;
    }
}

.img-responsive {
    width: 100%;
}

@media (max-width: $screen-sm-max) {
    .book-product-image {
        width: 100%;
   
        .img-responsive {
            max-width: 370px;
            margin: auto;
        } 
    }
}

    .image-teaser {
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        .img-responsive {
            width: 100%;
        }

        .image-teaser-text {
            background: $brand-blue;
            color: $white;
            padding: 15px 15px;
            text-align: center;
            position: relative;
            font-size: 16px;

            &::after {
                content: "";
                position: absolute;
                bottom: 12px;
                right: 12px;
                width: 7.8px;
                height: 14.3px;
                background-image: url("/Static/images/icons/svg/SIS_Ikon_Karusell_hoger_white_small.svg");
                background-size: 7.8px 14.3px;
            }
        }

        .image-teaser-hover {
            background: $brand-blue;
            color: $white;
            opacity: 0;
            transition: opacity ease .2s;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            padding: 20px;
            display: grid;
            justify-content: center;
            align-content: center;

            > p:last-child {
                margin-bottom: 0;
            }
        }

        > a {
            display: block;

            &:hover {
                text-decoration-color: $white;
                color: $white;

                .image-teaser-hover {
                    @media (min-width: $screen-md-min) {
                        opacity: 1;
                    }
                }
            }

            &:focus {
                text-decoration-color: $white;
            }
        }
    }

    .large-link {
        display: block;
        padding: 20px;
        color: $link-color;
        text-align: center;
        margin-bottom: 30px;
        overflow-x: hidden;
        background: $brand-gray-15;
        border-radius: 1em;

        &:hover,
        &:focus {
            color: $brand-blue;
            text-decoration: none;
            opacity: 0.7;
        }

        strong,
        em {
            display: block;
        }

        strong {
            font-size: $font-size-h3;
            font-weight: normal;
        }

        em {
            font-style: normal;
            font-size: $font-size-h4;
        }
    }

    .item-price {
        margin-top: 10px;
    }


    .separator {
        background: $brand-blue;
        height: 25px;
    }
