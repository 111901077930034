﻿@import "_variables.scss";
@import "_mixins.scss";

/* ALERTS */
.alert {
    position: relative;
    padding: 20px;
    padding-left: 60px;

    &:before {
        content: 'i';
        position: absolute;
        display: block;
        width: 30px;
        top: 20px;
        left: 15px;
        padding: 0 8px;
        line-height: 24px;
        background: $brand-gray-75;
        border-radius: 5px;
        text-align: center;
        font-weight: bold;
    }

    a {
        color: $link-color;
    }
}


.information {
    padding: 20px;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.alert-danger:before {
    color: $white;
    background: $alert-danger-msg-text;
}

.alert-warning {
    color: $alert-danger-msg-text;
    background-color: $alert-danger-msg-bg;
    border-color: $alert-danger-msg-border;

    &:before {
        color: $white;
        background-color: $alert-danger-msg-text;
    }
}

/* MEGA ALERT */
.mega-alert {
    display: block;
    background: $brand-blue-25;
    color: $brand-blue;
    text-align: center;
    font-size: 26px;
    padding: 20px;

    a:hover,
    a:focus {
        text-decoration: none;
        opacity: 0.8;
        color: $brand-blue-75;
    }

    @media (min-width: $screen-sm-min) {
        font-size: 28px;
        padding: 30px;
    }

    @media (min-width: $screen-md-min) {
        font-size: 32px;
        padding: 40px;
    }

    @media (min-width: $screen-lg-min) {
        font-size: 44px;
        padding: 50px;
    }
}
