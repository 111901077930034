﻿@import "_variables.scss";
@import "_mixins.scss";

.sub-menu {
    list-style: none;

    li {
        position: relative;

        strong a {
            font-size: 20px;
        }
    }

    .active > a {
        background: $brand-gray-15;
        color: $link-color;
        font-weight: 500;
    }

    .menu-toggle {
        border: none;
        background: url('/static/images/icons/svg/SIS_Ikon_Caret.svg') no-repeat center;
        background-size: 60%;
        width: 16px;
        height: 16px;
        padding: 10px;
        opacity: 0.25;
        transition: all ease .2s;
        margin-left: 5px;

        &:hover {
            opacity: 1;
        }
    }

    .has-children {
        > a {
        }

        > ul {
            display: none;
        }
    }

    .expanded {
        > a {
            color: $brand-blue;

            > .menu-toggle {
                transform: rotate(180deg);
            }
        }

        > ul {
            display: block;
        }
    }

    a {
        display: flex;
        color: $text-color;
        font-size: 16px;
        padding: 5px 10px;
        user-select: none;
        -webkit-user-select: none;
        align-items: flex-end;

        &:hover,
        &:focus {
            color: $text-color;
            background: $brand-gray-15;
            text-decoration: none;
        }
    }

    ul li > a {
        margin-left: 10px;

        &::before {
            content: '- ';
            margin-bottom: auto;
        }
    }

    ul ul li > a {
        &::before {
            content: '';
        }
    }

    &, ul {
        list-style: none;
        padding-left: 0;
    }

    @media (min-width: $screen-md-min) {
        &-sticky {
            &-container {
                display: flex;
                align-items: flex-start;
            }

            &-item {
                position: sticky;
                top: 0;
            }
        }
    }
}
