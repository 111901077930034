﻿@import "_variables.scss";
@import "_mixins.scss";


.hero {
    text-decoration: none;
    overflow: hidden;

    a {
        color: $white;
        display: contents;

        &:hover,
        &:focus {
            color: $white;
        }
    }

    .hero-image {
        display: block;

        &.has-text {
            position: absolute;
        }

        left: 0;
        right: 0;

        img {
            display: block;
            margin: 0 auto;
            width: 100%;
            object-fit: cover;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                width: auto; //IE Fix
            }
        }
    }

    &.hero-text--left {
        .hero-text-wrapper {
            justify-content: flex-start;
        }
    }

    .hero-text-wrapper {
        display: flex;
        justify-content: flex-end;
        padding: 0;
    }


    .hero-text {
        display: block;
        background: $brand-red;
        padding: 30px 60px 30px 30px;
        color: $white;
        line-height: 1.4;
        position: relative;
        margin: 150px 0 0 0;
        flex-basis: 100%;
        border-radius: 5px;
        overflow: hidden;

        @media (min-width: $screen-md-min) {
            margin: 50px 0;
            flex-basis: 50%;
        }

        &::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            width: 54px;
            height: 44px;
            background: rgba(255,255,255,0.3);
        }

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            width: 54px;
            height: 44px;
            background: url("/Static/images/icons/svg/SIS_Ikon_Karusell_hoger_white.svg") no-repeat;
            background-position: 22px 13px;
            background-size: 10px;
        }

        &:hover::after {
            animation: slide 0.25s ease 1;
        }

        h2, h3, h4, h5, h6 {
            font-weight: normal;

            strong {
                font-weight: bold;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        small,
        strong,
        p {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        cite {
            font-size: 14px;
        }

        blockquote {
            margin-bottom: 10px;
            border: none;
            padding: 0;
            font-size: 22px;
        }

        h2 {
            font-size: 28px;
        }
    }
}


@keyframes slide {
    0% {
        background-position: 22px 13px;
        opacity: 1;
    }

    50% {
        background-position: 54px 13px;
        opacity: 0;
    }

    50.1% {
        background-position: -32px 13px;
        opacity: 0;
    }

    100% {
        background-position: 22px 13px;
        opacity: 1;
    }
}
