﻿@import "_variables.scss";
@import "_mixins.scss";

.sticky {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    border-top: 0;
    border-bottom: 1px solid rgb(231, 233, 234);
    box-shadow: rgba(231, 233, 234, 0.75) 3px 0 5px;

    .search-input {
        width: 100% !important;
    }
}

.toolbox {
    background: $brand-gray-15;
    padding: 10px;

    @media (min-width:$screen-md-min) {
        padding: 15px 0;
    }

    &.admin--loggedin {
        background-color: $brand-beige-15;

        .panel--expanded .panel-heading {
            padding-bottom: 0;
        }
    }

    .search-module {
        display: flex;

        @media (max-width:$screen-sm-max) {
            padding-left: 0;
            padding-right: 0;
        }

        .search-container {
            display: flex;
            flex: 1;
            border-radius: 5px;
            box-shadow: 0 1px 3px 0 rgba(0,0, 0, .1);

            .form-control {
                border: none;
            }

            .search-opt {
                min-width:220px;
                width: fit-content;
                background: $brand-gray-45;
                height: 46px;
                padding: 5px 30px 5px 5px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                background-repeat: no-repeat;
                background-position-x: 95%;
                background-position-y: center;
                border-radius: 2px;
                display: none;

                @media (min-width:$screen-md-min) {
                    padding-left: 15px;
                    display: block;
                }
            }

            .search-box {
                flex: 1;
                position: relative;
                padding-right: 15px;
                padding-left: 15px;
                height: 46px;
                font-size: 16px;
                border: none;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;

                @media (min-width:$screen-md-min) {
                    border-radius: 0;
                }
            }

            @media screen and (max-width: $screen-sm-max) {
                .search-box::-webkit-input-placeholder { /* WebKit browsers */
                    color: transparent;
                }

                .search-box::-moz-placeholder { /* Mozilla Firefox 19+ */
                    color: transparent;
                }
            }

            .search-button {
                border: none;
                flex-basis: 50px;
                padding-top: 10px;
                padding-bottom: 8px;
                line-height: $line-height-base;
                font-size: $font-size-base;
                background: url('/Static/images/icons/svg/SIS_Ikon_Sok.svg') $brand-gray-45 no-repeat center center;
                background-size: 60%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;

                &:hover {
                    background-color: $brand-gray-15;
                }
            }
        }
    }
}

.toolbox-cart {
    background: url('/Static/images/SIS_Ikon_Shopping.svg') right bottom no-repeat;
    width: 53px;
    height: 38px;
    padding-top: 4px;
    padding-left: 4px;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    color: $text-color-light;
    text-align: center;
    margin: 4px 0 0 20px;

    &:hover {
        color: $brand-red;
        text-decoration: none;
    }


    @media (max-width: $screen-sm-max) {
        display: none !important;
    }
}
