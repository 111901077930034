﻿@import "_variables.scss";
@import "_mixins.scss";


.gdpr-errormessage {
    padding-top: 15px;
    font-weight: bold;
    color: $brand-red;
}

span[data-valmsg-for="GDPRAccept"] {
    color: $brand-red;
    font-weight: bold;
}


.gdpr-link {
    color: #417BAA !important;
    text-decoration: none;

    &:hover {
        color: #2c5373 !important;
        text-decoration: underline !important;
    }

    &:focus {
        color: #2c5373 !important;
        text-decoration: underline !important;
    }
}
