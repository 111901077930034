﻿@import "_variables.scss";
@import "_mixins.scss";

.item-icon-puff {
    width: 60px;
}

.item-icon {
    margin-right: 20px;
    float: left;

    img {
        width: 32px;
    }
}

.item-icon-60 {
    margin-right: 20px;
    float: left;

    img {
        min-width: 60px;
    }
}

@media (max-width: 400px) {
    .item-icon:not(.always-show) {
        display: none;
    }
}

.icon {
    display: inline-block;
    vertical-align: middle;
}

.icon-message {
    background: url('/Static/images/icons/svg/SIS_Ikon_Meddelande.svg');
    background-size: 23px 20px;
    width: 23px;
    height: 20px;
}

.icon-web-nav {
    background: url('/Static/images/icons/svg/SIS_Ikon_Web_Nav.svg');
    background-size: 36px 36px;
    width: 36px;
    height: 36px;
}


.share-icons {
    @include module-margin-top();
    margin-bottom: 0;

    a {
        display: inline-block;
        @include module-margin();
        height: 42px;
        vertical-align: middle;
        margin-right: 5px;

        &:hover {
            opacity: 0.5;
        }

        img {
            margin-top: 5px;
        }
    }

    .btn {
        margin-right: 0;
    }

    .btn-pdf {
        float: right;
    }
}

.calendar-icon {
    display: inline-block;
    position: relative;
    width: 45px;
    height: 63px;
    text-align: center;
    text-transform: uppercase;
    background: url("/Static/images/icons/svg/SIS_Ikon_Kalender.svg") no-repeat;
    background-size: 45px;

    .calendar-icon-part1::before,
    .calendar-icon-part2::before {
        display: block;
        position: absolute;
        top: -8px;
        content: '';
        width: 8px;
        height: 12px;
    }

    .calendar-icon-part1 {
        display: block;
        font-size: 13px;
        margin-top: 13px;
        height: 13px;
        font-weight: bold;

        &::before {
            left: 5px;
        }
    }

    .calendar-icon-part2 {
        display: block;
        height: 30px;
        line-height: 30px;
        font-size: $font-size-base;
        font-weight: bold;
        font-size: 18px;

        &::before {
            right: 5px;
        }
    }
}

.calendar-minus {
    font-size: 40px;
}

.icon-plus {
    font-size: 30px;
    line-height: 1;
}

.icon-plus--green {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    display: inline-block;

    img {
        border-radius: 5px;
    }
}

[class^="icon_lang_"] {
    padding-left: 16px;
    margin-right: 3px;
    height: 10px;
    position: relative;
    top: 1px;
}

.icon_lang_text {
    padding-left: 25px;
    background-size: 22px !important;
    background-position: center left !important;
}

.icon_lang {
    &_fi {
        background: url(/Static/images/flags/fi.svg) no-repeat;
    }

    &_no {
        background: url(/Static/images/flags/no.svg) no-repeat;
    }

    &_sv {
        background: url(/Static/images/flags/sv.svg) no-repeat;
    }

    &_en {
        background: url(/Static/images/flags/en.svg) no-repeat;
    }

    &_zh {
        background: url(/Static/images/flags/zh.svg) no-repeat;
    }

    &_de {
        background: url(/Static/images/flags/de.svg) no-repeat;
    }

    &_da {
        background: url(/Static/images/flags/da.svg) no-repeat;
    }

    &_el {
        background: url(/Static/images/flags/el.svg) no-repeat;
    }

    &_es {
        background: url(/Static/images/flags/es.svg) no-repeat;
    }

    &_fr {
        background: url(/Static/images/flags/fr.svg) no-repeat;
    }

    &_it {
        background: url(/Static/images/flags/it.svg) no-repeat;
    }

    &_ja {
        background: url(/Static/images/flags/ja.svg) no-repeat;
    }

    &_nl {
        background: url(/Static/images/flags/nl.svg) no-repeat;
    }

    &_pl {
        background: url(/Static/images/flags/pl.svg) no-repeat;
    }

    &_ru {
        background: url(/Static/images/flags/ru.svg) no-repeat;
    }
}
