@import "_variables.scss";
@import "_mixins.scss";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/font-awesome/scss/font-awesome.scss";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "_alerts.scss";
@import "_blocks.scss";
@import "_bootstrap-select.scss";
@import "_breadcrumbs.scss";
@import "_buttons.scss";
@import "_carousel.scss";
@import "_font.scss";
@import "_footer.scss";
@import "_forms.scss";
@import "_gdpr.scss";
@import "_header.scss";
@import "_hero.scss";
@import "_icons.scss";
@import "_jquery_ui.scss";
@import "_lightbox.scss";
@import "_list-groups.scss";
@import "_nav-tabs.scss";
@import "_newsfeed.scss";
@import "_onlineservice";
@import "_onlineservice-export-pdf";
@import "_perspektiv";
@import "_order-history.scss";
@import "_panels.scss";
@import "_searchpage.scss";
@import "_shoppingcart.scss";
@import "_submenu.scss";
@import "_tables.scss";
@import "_themepage.scss";
@import "_toolbox.scss";
@import "_utils.scss";

html {
    height: 100%;
    font-size: 16px;
    overflow-x: clip;
}

body {
    overflow-x: clip;
    word-break: break-word;
    height: 100%;
    font-size: 16px;
}

.nav > li > a {
    display: block;
    user-select: none;
    -webkit-user-select: none;

    &:hover {
        text-decoration: none;
    }
}

.badge {
    color: #FFF;
    border-radius: 5px;
}

h1, .h1 {
    font-size: 28px;
}

.h2, h2 {
    font-size: 26px;
}

.h3, h3 {
    font-size: 22px;
}

h5 {
    font-size: 1rem;
}

.container-fluid {
    max-width: 1250px;

    .container-fluid {
        padding: 0;
    }
}

.dropdown-toggle {
    background: #FFF;
    border: solid 1px $brand-gray;
    border-radius: 0;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid;
}

.caret, .order-item-caret::after {
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

label {
    font-weight: normal;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 1.3em;
    margin-bottom: 0.5em;
    font-weight: bold;

    a {
        color: $text-color;

        &:hover,
        &:focus {
            color: $text-color;
        }
    }

    &:first-child {
        margin-top: 0;
    }
}

hr {
    border-top-color: $black;
    opacity: 0.15;
}

.article {
    h2 {
        font-size: $font-size-h2;
    }

    h3 {
        font-size: $font-size-h3;
    }

    h4 {
        font-size: $font-size-h4;
    }

    h5 {
        font-size: $font-size-h5;
        font-weight: bold;
    }

    h6 {
        font-size: $font-size-h6;
        font-style: italic;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto !important;
    }

    .article-heading {
        margin-top: 0;
    }

    .introduction {
        font-weight: bold;
    }

    ul {
        list-style: none;
        padding-left: 20px;

        li:before {
            content: "•";
            position: absolute;
            margin-left: -20px;
            color: $brand-red;
            font-size: 27px;
            line-height: 0.9;
        }

        li.no-bullet::before {
            content: "";
            position: absolute;
            margin-left: -20px;
            color: $brand-red;
            font-size: 27px;
            line-height: 0.9;
        }
    }

    table td {
        padding: 5px !important;
        vertical-align: top
    }
}

.icsCodesModal {
    max-width: 1000px;
}

select {
    user-select: none;
    -webkit-user-select: none;
}

#available-cards {
    width: 55%;
}

img, iframe, video {
    max-width: 100%;
}

iframe {
    border-style: none;
}

p {
    word-wrap: break-word;
}

cite {
    font-family: $font-family-monospace, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
}

/* PANE */
.pane {
    position: relative;
    overflow: hidden;
    color: $white;

    .popover {
        color: $text-color;
    }

    a {
        color: $white;
    }
}

.link-styling a {
    color: $brand-blue-75 !important;

    &:hover {
        text-decoration: underline !important;
    }
}

.button-holder {
    display: block;

    a.btn {
        margin-left: auto;
        margin-right: auto;
        display: table;
    }
}

/* META ROW */
.meta-row {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 22px;
    margin-bottom: 5px;

    .badge {
        font-size: 12px;
        font-weight: normal;
        background: $brand-blue;
        text-transform: uppercase;
        padding: 6px 10px 3px;
        vertical-align: middle;
        margin: 0 5px 10px 0;
        white-space: nowrap;
        display: flex;
        align-items: center;
        width: fit-content;
    }
}

.badge-mono {
    max-width: 55%;
    color: $white;
    font-family: $font-family-monospace;
    font-size: 12px;
    background: $brand-blue;
    padding: 4px 10px 1px;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 5px;
    text-transform: uppercase;
    line-height: 18px;

    &:empty {
        background: transparent;
        height: 18px;
    }
}

.item-cover {
    width: 45px;
    height: 59px;
}

.control-label {
    display: block;
    font-size: 14px;
    font-family: $font-family-monospace;
}

.form-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    & > div {
        margin-bottom: 0;
        flex-grow: 1;
    }

    & > div, & > button {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    @media (max-width:$screen-sm-max) {
        flex-wrap: wrap;
        justify-content: initial;

        .form-group {
            width: 100%;
            margin: 0 0 15px 0;
        }
    }
}

a.jobylon-apply-btn {
    @include btn-rounded();
    font-size: 16px !important;
    background-color: $btn-primary-bg !important;
    border-color: $btn-primary-bg !important;
    transition: background-color 0.25s ease, border-color 0.25s ease;

    &[disabled] {
        @include button-variant($white, $brand-gray, $brand-gray);
    }

    &.btn-sm {
        font-size: 15px;
    }

    &:hover {
        background-color: $brand-red-75 !important;
        border-color: $brand-red-75 !important;
    }
}

.dropdown-menu {
    margin-top: -1px;
    margin-right: 1px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.bottomFooterClass, #LstContent #bottomact, #rpd-content #actions {
    margin-top: 20px;
    padding: 10px 40px;
    text-align: left;
}
/* clearing methods */

.clearfix:before, #LstContent #bottomact:before, #JDescContent #JD-Actions:before, #JD-Actions:before, #rpd-content #actions:before, .clearfix:after, #LstContent #bottomact:after, #JDescContent #JD-Actions:after, #JD-Actions:after, #rpd-content #actions:after {
    content: "";
    display: table;
}

.clearfix:after, #LstContent #bottomact:after, #JDescContent #JD-Actions:after, #JD-Actions:after, #rpd-content #actions:after {
    clear: both;
}

#LstContent #bottomact .linkbracket, #JDescContent #JD-Actions .linkbracket {
    display: none;
}

#LstA-SrCrtLabel, .NewPopUp {
    display: none;
}

div#backToSearch {
    display: none !important;
}

#LstContent #bottomact a, #JDescContent #JD-Actions a, #rpd-content #actions input, #footersubmitbav input, #sectionAttachContent input[type="submit"] {
    /*.btn;
    .btn-primary;
    .btn-sm;*/
    //TODO!
}

.JD-BButtonFace:hover {
    text-decoration: none;
}

#JD-Actions div {
    display: inline-block !important;
    padding-right: 10px;
}

.delete-category-filter {
    margin-left: 10px;
    vertical-align: middle;
}

.expander {
    overflow: hidden;
    cursor: pointer;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    @include caret();

    &::before {
        position: absolute;
        border-width: 6px;
        opacity: 0.7;
        right: 25%;
        margin-top: 10px;
    }
}

.tag {
    display: inline-block;
    background: $brand-gray-15;
    padding: 15px 10px 12px;
    margin: 0 2px 5px 0;
    font-size: 14px;
    white-space: normal;
    font-family: $font-family-monospace;
    border-radius: 5px;

    &:hover,
    &:focus {
        background: darken($brand-gray-15, 5%);
        text-decoration: none;
    }

    @media (max-width: $screen-xs-max) {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 300px;
    }
}

.expandable-area {
    display: none;
}

.expand-area,
.contract-area,
.more-area {
    display: none;
    padding: 10px 20px;
    text-align: right;
    font-size: 16px;
    border-top: solid 1px $brand-gray-45;
}

.more-area {
    display: block;
    background: $brand-gray-15;
}

.expand-area {
    .expandable-area + & {
        display: block;
        background: $brand-gray-15;
    }

    @include caret();
}

.contract-area {
    @include caret(up);
    background: $brand-gray-15;
}

.participant-remove-button {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-left: 10px;
    background: url('/Static/images/icons/svg/SIS_Ikon_Stang.svg') center center no-repeat;
    background-size: 20px 20px;
}

/* SYSTEM BAR */
.systembar {
    padding: 10px 20px;
    font-size: 16px;
    color: $white;
    text-align: center;

    .modal-open & {
        display: none !important;
    }

    a {
        color: $white;
        text-decoration: underline;
    }

    .button {
        @include button-variant($white, transparent, $white);
        border-radius: 20px;
        display: inline-block;
        padding: 9px 14px 7px;
        line-height: 1;
        border-style: solid;
        border-width: 1px;
        margin-left: 20px;
        text-transform: uppercase;
        font-size: 14px;
        text-decoration: none;

        &:hover,
        &:focus {
            background: rgba(0, 0, 0, 0.25);
        }
    }
}

.systembar {
    background: $brand-red-75;
    display: none;

    .button {
        &:hover,
        &:focus {
            background: rgba(0, 0, 0, 0.1);
            border-color: $white;
        }
    }
}

/* GO TO TOP */
.go-to-top {
    position: fixed;
    z-index: 999;
    bottom: 0;
    right: 40px;
    display: block;
    background: $brand-red url('/Static/images/icons/svg/SIS_Ikon_Karuesell_upp_white.svg') no-repeat center center;
    background-size: 20px 20px;
    width: 44px;
    height: 44px;
    margin-bottom: -100px;
    transition: background ease 0.25s;

    @media (prefers-reduced-motion) {
        transition: none;
    }

    &.bottom {
        background: $brand-gray-15 url('/Static/images/icons/svg/SIS_Ikon_Karuesell_upp_red.svg') no-repeat center center;
        background-size: 20px 20px;
        transform: rotate(0deg);
    }
}

.cart-table {
    .grid-row {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 40px;
        grid-template-rows: auto;
        grid-column-gap: 20px;
    }
}

.singlecopy-purchase-message {
    background-color: $alert-danger-msg-bg;
    border: 1px solid $alert-danger-msg-border;
    color: $alert-danger-msg-text;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 10px;
}

.image-top {
    top: 0;
    position: absolute;
    margin-top: 20px;
}

img[src*="/Static/images/ajax_loader.gif"] {
    display: block;
    margin: auto;
    padding: 20px;
}

#autocompletediv {
    display: block;
    position: relative;

    li {
        padding: 5px 20px;

        div {
            border-color: transparent;
        }
    }
}

#my-services .list-group .list-group-item {
    border-top: none !important;
}

#my-standards-page .list-group .list-group-item {
    border-top: none !important;
}

#my-messages .list-group .list-group-item {
    border-top: none !important;
}

.accordion {
    background-color: $body-bg;
    margin: 10px 0;

    a {
        color: $link-color;
    }

    .relations {
        padding-left: 10px;
    }
}

@keyframes hbbFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeUp {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.navigationBar {
    a {
        color: white;
        cursor: default;

        &:hover {
            text-decoration: none;
        }

        &.tabButton {
            padding: 8px 16px;
            float: left;
            width: auto;
            border: none;
            display: block;
            outline: 0;
            background-color: $brand-blue-75;

            &:hover {
                background-color: $brand-blue;
                border-color: $brand-blue;
                color: $white;
            }

            &:focus,
            &:active {
                border-color: $brand-blue-45;
                background-color: $brand-blue-45;
                color: $white;
            }
        }

        &.tabButtonActive {
            background-color: $brand-blue;
            border-color: $brand-blue;
            color: $white;
        }
    }
}

.demo-error {
    color: #E32431;
    margin-bottom: 30px;

    a {
        color: #417BAA;

        &:hover {
            color: #2c5373;
            text-decoration: underline;
        }
    }
}

.demo-success {
    a {
        color: #417BAA;

        &:hover {
            color: #2c5373;
            text-decoration: underline;
        }
    }
}

.system-info {
    table {
        table-layout: fixed;
    }

    dt {
        text-align: left;
        width: 230px;
    }

    dd {
        margin-left: 230px;
    }

    .user-header {
        display: inline-block;
    }

    .btn {
        float: right;
    }
}

#hero-block .hero-search .ui-autocomplete li > div, #searchformid #autocompletediv > .ui-autocomplete > li > div {
    padding-left: 20px !important;
    font-weight: 600 !important;
}

#hero-block .hero-search .ui-autocomplete li > div .sg-color-search-query, #searchformid #autocompletediv > .ui-autocomplete > li > div .sg-color-search-query {
    font-weight: 500 !important;
}

#related-container {
    #related-icon {
        display: inline-block;
        height: 18px;
        width: 18px;
        position: relative;
        bottom: 1px;
    }

    #related-button {
        display: inline-block;
        margin-left: 7px;
    }

    #related-button:hover + #ics-tooltip,
    #related-button:focus + #ics-tooltip {
        display: block;
    }

    #ics-tooltip {
        display: none;
        position: relative;
    }

    #tooltip-text {
        text-align: left;
        padding: 5px 5px;
        font-weight: 400;
        font-size: 12px;
        border-radius: 3px;
        z-index: 1000;
        border: 1px solid;
        background-color: #fff;
        position: absolute;
    }

    .panel-title {
        display: inline-block;
    }
}

#allready-registered {
    color: red;
}

.progress {
    position: relative;
    height: 35px;
    background: $brand-gray;
    line-height: 30px;
    border-radius: 5px;
    margin-top: 20px;
    font-family: "LL Akkurat";

    > .progress-bar {
        background: $brand-blue;
    }

    > .progress-completed {
        font-size: 16px;
        position: absolute;
        right: 0;
        left: 0;
        text-align: center;
        font-weight: bold;
        padding: 3px 10px 2px;
        color: #FFF;
    }
}

.product-info p {
    font-family: $font-family-monospace;
    font-size: 16px;
    margin-bottom: 0.5rem;

    strong {
        font-family: $font-family-base;
    }
}

.tinyMce {
    padding: 10px;
}

.two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.btn-clock {
    padding-right: 35px !important;
    background-image: url(/Static/images/icons/svg/SIS_ikon_klocka.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.infobox {
    border: solid 1px $black;
    padding: 15px;
    margin-bottom: 15px;

    *:last-child {
        margin-bottom: 0;
    }
}

.infobox-gray {
    background: #F5F6F6;
    padding: 15px;
    margin-bottom: 15px;

    *:last-child {
        margin-bottom: 0;
    }
}

.infobox-blue {
    background: #CCDCE6;
    padding: 15px;
    margin-bottom: 15px;

    *:last-child {
        margin-bottom: 0;
    }
}

.infobox-beige {
    background: #F2F0ED;
    padding: 15px;
    margin-bottom: 15px;

    *:last-child {
        margin-bottom: 0;
    }
}

.infobox-green {
    background: #CCDCDC;
    padding: 15px;
    margin-bottom: 15px;

    *:last-child {
        margin-bottom: 0;
    }
}

.tooltip {
    .tooltip-inner {
        max-width: 460px !important;

        @media (max-width:$screen-sm-max) {
            max-width: 270px !important;
            min-width: 270px;
            position: relative;
        }

        @media (max-width:$screen-md-max) {
            transform: translateX(5px);
        }

        min-width: 320px;
        padding: 20px;
        border-radius: 10px;
        background: #FFF;
        color: #000;
        border: solid 1px #000;
        text-align: left;

        *:last-child {
            margin-bottom: 0;
        }
    }

    &.in {
        opacity: 1 !important;
    }
}

.mce-preview-object.mce-object-iframe {
    width: 100%;
}

.icsFilter-item {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    ul {
        padding-left: 20px;
    }

    .ics-label {
        display: flex;
        align-items: center;
    }

    .toggle {
        position: absolute;
        margin-left: -20px;
        margin-top: -31px;
    }
}
.disabledTag {
    pointer-events: none;
}

@import "jquery-ui.min.css";
