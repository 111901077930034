﻿@import "_variables.scss";
@import "_mixins.scss";

#searchPage {
    order: 3;
}

.searching {
    input, button, select, label {
        pointer-events: none;
    }
}

.search-result {
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
    user-select: none;
    -webkit-user-select: none;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);

    @media (min-width:$screen-md-min) {
        flex-direction: row;
        padding: 20px 15px;
    }

    &.insubscription {
        background-color: $brand-beige-15;
    }

    .item-icon {
        width: 45px;
        flex-basis: 45px;
        margin-right: 0;

        @media (max-width:$screen-sm-max) {
            display: none;
        }
    }

    .item-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > a {
            display: flex;
            flex-direction: column;
            overflow: visible;
            color: $text-color;

            &:hover {
                text-decoration: none;

                .title {
                    text-decoration: underline;
                }
            }
        }

        .meta-row {
            border-radius: 5px;
            width: fit-content;
            display: flex;

            .badge {
                border-radius: 5px;
                margin: 0;
                padding: 7px 10px 5px 10px;
            }

            strong {
                background: $brand-blue-25;
                padding: 7px 10px 5px 10px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                margin-left: -3px;
                line-height: 1;
                font-size: 12px;
            }
        }

        .title {
            display: block;
            flex: 1;
            font-size: 16px;
            color: $text-color;
            margin: 5px 0;
        }

        .item-meta {
            font-size: 14px;
            margin-top: 5px;
            line-height: 1em;
            display: flex;
            flex-direction: row;
            gap: 10px;
            flex-wrap: wrap;
            align-items: baseline;

            span {
                display: inline-block;
                flex-shrink: 0;
            }

            strong {
                margin-right: 3px;
                display: inline-block;
            }

            .invalid {
                color: $brand-red;
                font-weight: bold;
            }

            .pdf-preview {
                font-weight: bold;
                margin-left: auto;

                @media (max-width:$screen-sm-max) {
                    margin-top: 15px;
                    margin-block: 15px;
                }
            }

            .pdflink {
                margin-left: 10px;
            }
        }

        .relations {
            flex-basis: 100%;
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            .amendedby, .correctedby {
                display: flex;
            }
        }
    }


    .sidebar {
        text-align: right;
        display: flex;
        gap: 20px;
        align-items: flex-end;
        margin: 15px 0 0 0;
        flex-direction: row-reverse;
        justify-content: space-between;
        position: relative;

        @media (min-width:$screen-md-min) {
            justify-content: flex-start;
            margin: 0 0 0 0px;
            flex-basis: 210px;
            flex-direction: column;
            padding: 0;
            align-items: flex-end;

            .btn-primary {
                width: 70%;
            }
        }

        .sidebar-links {
            width: 100%;
        }
            
        .showListPopUp {
            border: none;
            background: none;
            padding: 0;
        }

        .item-favorite {
            font-size: 14px;
            display: flex;
            align-items: baseline;
            gap: 10px;
            justify-content: flex-end;
            margin-top: auto;

            label {
                margin: 0;
            }
        }

        .search-price {
            line-height: 1em;
            white-space: nowrap;
            display: flex;
            align-items: flex-end;


            .search-price-type {
                margin-right: 5px;
                font-size: 12px;
                font-weight: bold;
                line-height: 12px;

                &:after {
                    content: ":";
                }
            }

            .promotion {
                display: flex;
                flex-direction: column;
                gap: 0;

                strong {
                    color: $brand-red;
                    display: inline-block;
                }

                span {
                    display: inline-block;
                    text-decoration: line-through;
                    font-size: 14px;
                }
            }
        }

        .pdf-preview {
            display: flex;
            width: 32px;
            height: 32px;
            flex-shrink: 0;

            @media (min-width:$screen-md-min) {
                position: absolute;
                top: 50px;
                left: 20px;
            }
        }

        .btn-primary {
            font-size: 14px;
            border-radius: 5px;
        }
    }
}
//TODO: Check if this workaround fails somewhere
.ui-dialog {
    position: fixed !important;
    top: 5% !important;
}

#allIcsCodes {
    height: calc(100% - 108px) !important;
    overflow-y: auto;

    @media (max-width:$screen-sm-max) {
        padding: 5px !important;
    }

    label > span {
        margin-right: 10px;

        > strong {
            margin-right: 10px;
        }
    }

    > div {

        > label {
            background: $brand-red;
            color: #FFF;
            padding: 10px 0px 10px 35px;
            margin: 1px 0;
            border-radius: 5px;

            > a {
                color: #FFF;
                margin-top: -2px;
            }
        }

        .custom-checkbox {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;

            input + .custom-checkbox {
                flex-shrink: 0;
                margin-left: auto;
            }
        }

        a {
            transform: rotate(-90deg);
            transition: all ease .2s;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-left: -25px;
            position: absolute;
            margin-top: -3px;

            &.open {
                transform: rotate(0deg);
            }
        }



        > div {
            display: none;
            padding: 5px 0 5px 35px;
            background: $brand-gray-15;
            margin: 1px 0;

            &.open {
                display: block;
            }

            > div {
                display: none;
                padding: 2px 0 2px 0;
                background: $brand-gray-25;
                margin: 1px 0px;

                &.open {
                    display: block;
                }
            }
        }
    }
}

.icsCodesDialog {
    width: 96% !important;
    left: 2% !important;
    right: 2% !important;
    top: 2% !important;
    bottom: 2% !important;
    max-width: 850px;
}

.search-header {
    margin-top: 20px;
    user-select: none;
    -webkit-user-select: none;
    padding: 20px 0px;

    @media (max-width:$screen-md-max) {
        padding: 20px 40px;
        margin-top: 0;
    }

    h2 {
        margin: 0;
        font-size: 22px;
    }

    .call, .email {
        text-align: center;
        font-weight: bold;
        color: #000;


        &:before {
            content: "";
            width: 70px;
            height: 70px;
            background: $brand-blue;
            display: block;
            border-radius: 60px;
            margin-bottom: 5px;
            background-repeat: no-repeat;
            background-size: 32%;
            background-position: center;
        }
    }

    .email:before {
        background-image: url('/static/images/icons/svg/SIS_Ikon_mail.svg');
        background-size: 50%;
    }

    .call:before {
        background-image: url('/static/images/icons/svg/SIS_Ikon_phone.svg');
    }
}

.filter-shadow {
    @media (max-width:$screen-md-max) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        filter: brightness(0.8);
        backdrop-filter: blur(1px);
        touch-action: none;
    }
}

.filter-toggle {
    display: block;

    @media (min-width:$screen-lg-min) {
        display: none;
    }

    width: 40px;
    height: 50px;
    background: url(/Static/images/icons/svg/SIS_Ikon_filter.svg) $brand-beige-15 no-repeat center center;
    position: fixed;
    left: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    cursor: pointer;
    box-shadow: 0 1px 4px 0px rgba(0,0,0, .5);
    z-index: 100;
    transition: all .2s ease;
    margin-top: 7px;

    &.open {
        margin-left: 495px;
        box-shadow: none;

        @media (max-width:$screen-sm-max) {
            margin-left: calc(100% - 55px);
        }
    }
}

.search-filter-main {
    padding: 20px;
    margin-top: 20px;
    background: $brand-beige-15;
    user-select: none;
    -webkit-user-select: none;
    display: flex;
    flex-direction: column;
    overscroll-behavior: none;
    border-radius: 5px;

    @media (max-width:1250px) {
        margin-left: -15px;
    }

    @media (max-width:$screen-sm-max) {
        max-width: calc(100% - 50px) !important;
    }

    @media (max-width:$screen-md-max) {
        margin-top: 0px;
        max-width: 500px;
        z-index: 100;
        width: 100%;
        transform: translateX(-100%);
        transition: all .2s ease;
        position: fixed;
        top: 0;
        bottom: 0;

        .search-filter-container {
            overflow-y: auto;
            overflow-x: hidden;
            flex: 1;
        }

        .clearfilter {
            margin-top: 10px;
        }

        &.open {
            transform: translateX(0%);
        }
    }

    .search-filter-container {
        margin-top: 10px;
    }

    > h3 {
        margin: 0 0 20px 0;
    }

    .help {
        display: block;
        width: 20px;
        height: 20px;
        background: url('/Static/images/icons/svg/SIS_Ikon_Fragetecken.svg') no-repeat center center;
        margin: -2px 2px 0 2px;
        opacity: 0.5;
        transition: all .2s;
        margin-left: auto;

        &:hover {
            opacity: 1;
        }
    }

    .clearfilter {
        margin-top: 40px;
    }

    .filter-icon {
        margin-right: 7px;
        width: 20px;
        margin-top: -2px;
    }

    .form-group {
        margin: 1rem 0 0 0;

        .filter-label {
            background: $brand-beige-25;
            padding: 10px 5px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:before {
                margin: 0 10px 0 5px;
                display: inline-block;
                vertical-align: .255em;
                content: "";
                border-top: .3em solid;
                border-right: .3em solid transparent;
                border-bottom: 0;
                border-left: .3em solid transparent;
            }
        }

        &.open {
            .filter-label {
                &:before {
                    transform: rotate(180deg);
                }
            }

            .filter {
                margin: 10px 0;
                display: block;
            }
        }

        .filter {
            display: none;
        }
    }

    .filter-label {
        font-weight: bold;
        font-size: 16px;
        font-family: "LL Akkurat",Arial,sans-serif;
        margin: 0 -3px;
    }

    label.custom-checkbox {
        position: relative;
        display: block;
        height: 20px;
        cursor: pointer;

        .custom-checkbox {

            & + span {
                position: absolute;
                left: 30px;
                right: 40px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        small {
            padding: 2px 5px;
            position: absolute;
            right: 0;
            background: $brand-beige-15;
            color: #aaa;
        }
    }
}
