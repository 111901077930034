﻿@import "_variables.scss";
@import "_mixins.scss";


.formcontainerblock {
    form {
        h2.Form__Title {
        }

        aside.Form__Description {
        }

        div.Form__Status {
            span {
                &.Form__Status__Message {
                }

                &.Form__Warning__Message {
                }
            }
        }
    }

    div.Form__Element__Caption {
        width: 100%;
    }

    div.FormChoice--Image__Item {
        img {
            width: 98%;
        }
    }

    .Form__NavigationBar .Form__NavigationBar__Action.btn.btn-primary {
        background-image: inherit;
        border: 0;
        color: #fff;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 2em;
        max-width: inherit;
        width: auto;
    }

    div.FormCaptcha {
        label.Form__Element__Caption {
            display: block;
        }
    }

    div.FormRange {
        label.FormTitle {
            display: block;
            width: 100%;
        }

        input.FormRange__Input {
            width: 80%;
        }
    }

    div.FormSelection {
        .Form__Element__Caption {
            width: 100%;
        }
    }

    div.FormTextbox {
        .Form__Element__Caption {
            width: 100%;
        }
    }

    div.Form__Submit {
        width: 100%;
    }

    div.FormCaptcha {
        img {
            margin-bottom: 3px;
        }

        .Form__Element__Caption {
            width: 100%;
        }

        .FormTextbox__Input {
            display: inline-block;
        }
    }

    .Form__SubmitSuccessMessage {
        display: none;
    }
}

.field-validation-valid {
    display: none;
}

.validation-summary-valid {
    display: none;
}

select.form-control.input-validation-error {
    border: 3px solid $alert-danger-border;
    background-color: $brand-gray-75;
}

input.form-control.input-validation-error {
    border: 3px solid $alert-danger-border;
    background-color: $brand-gray-75;
}


label.custom-radio {
    line-height: 20px;
    padding-left: 30px;
    user-select: none;
    -webkit-user-select: none;

    &--disabled {
        color: #ccc;
    }
}

.custom-switch {
    display: flex;
    align-items: center;
    padding-left: 0;

    input {
        position: absolute;
        left: -999em;

        &:is(:checked) {
            + .custom-checkbox {
                background: $brand-red;
                border: solid 1px #b16767;

                &:before {
                    right: 1px;
                }
            }
        }
    }

    .custom-checkbox {
        width: 40px;
        height: 22px;
        background: #fff;
        position: relative;
        display: inline-block;
        border-radius: 20px;
        margin: 0 5px 0 0;
        border: solid 1px #bbb;

        &:before {
            content: "";
            background: #444;
            width: 18px;
            height: 18px;
            border-radius: 20px;
            display: block;
            position: absolute;
            margin: 1px;
        }
    }
}

.custom-radio input {
    position: absolute;
    left: -999em;
}

.custom-radio input + .custom-radio {
    position: static;
    left: auto;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('/Static/images/radio--unchecked.svg');
    margin-right: 6px;
    vertical-align: text-bottom;
    margin-left: -30px;
}

.custom-radio:hover input:not([disabled]) + .custom-radio {
    background: url('/Static/images/radio--hover.svg');
}

.custom-radio input:checked + .custom-radio {
    background: url('/Static/images/radio--checked.svg');
}

.custom-radio input:checked:disabled + .custom-radio {
    background: url('/Static/images/radio--checked--disabled.svg');
}

.custom-radio.white input + .custom-radio {
    background: url('/Static/images/radio--white--unchecked.svg');
}

.custom-radio.white:hover .custom-radio {
    background: url('/Static/images/radio--white--hover.svg');
}

.custom-radio.white input:checked + .custom-radio {
    background: url('/Static/images/radio--white--checked.svg');
}

label.custom-checkbox {
    line-height: 20px;
}

.custom-checkbox input {
    position: absolute;
    left: -999em;
}

.custom-checkbox input + .custom-checkbox {
    float: left;
    position: static;
    left: auto;
    display: inline-block;
    width: 22px;
    height: 22px;
    background-color: #FFF !important;
    margin-right: 10px;
    vertical-align: text-bottom;
    border: solid 1px #bbb;
    border-radius: 5px;
}

.custom-checkbox input + .custom-checkbox.custom-checkbox--sm {
    background-size: 17px 17px !important;
    background-position: center center!important;
    height: 17px;
    width: 17px;
    margin-right: 5px;
    position: relative;
    top: 1px;
}

.custom-checkbox:hover .custom-checkbox {
    background-color: #EEE !important;
}

.custom-checkbox input:checked + .custom-checkbox, .custom-checkbox input:checked + input + .custom-checkbox {
    background: url('/Static/images/checkbox--checked.svg');
}

.custom-checkbox input:disabled + .custom-checkbox, .custom-checkbox input:disabled + input + .custom-checkbox {
    background: url('/Static/images/checkbox--checked.svg');
}

.custom-checkbox input:disabled + .custom-checkbox {
    opacity: 0.5;
    filter: grayscale(1);
}

.custom-checkbox.white input + .custom-checkbox {
    background: #FFF;
}

.custom-checkbox.white:hover .custom-checkbox {
    background: #eee;
}

.custom-checkbox.white input:checked + .custom-checkbox, .custom-checkbox.white input:checked + input + .custom-checkbox {
    background: url('/Static/images/checkbox--white--checked.svg');
}

.js .inputfile, .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    max-width: 80%;
    font-size: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    padding: 7px 20px;
    border-radius: 2em;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted $black;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -4px;
    margin-right: 4px;
}


.inputfile-1 + label {
    color: $white;
    background-color: $brand-blue-75;
}

.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
    background-color: $brand-blue;
}


.form-control {
    font-weight: normal;
    border: 1px solid $brand-gray-75;
    box-shadow: inset 0 0 0 0 transparent;
    padding: 6px 12px 6px;

    &:focus {
        border-color: $brand-gray-15;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 5px rgba(100,100,100,.6);
    }
}

.form-control, output {
    color: $text-color-light;

    &::placeholder {
        color: $text-color-lighter;
        opacity: 1;
        overflow: visible;
    }
}

.form-control.white {
    border-color: $white;
    box-shadow: none;
}

.control-label {
    &.required {
        &:after {
            content: "*";
            margin-left: 2px;
            color: $brand-red;
            font-size: 24px;
            position: absolute;
            line-height: 24px;
        }
    }
}
