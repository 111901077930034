﻿@import "_variables.scss";
@import "_mixins.scss";

.navbar-nav-wrapper {
    position: relative;
    margin: 0 -10px;
    overflow: hidden;
}

#navbar {
    @media (max-width: $screen-md-max) {
        display: flex;
        flex-direction: column;
    }
}

.navbar-toggle {
    display: none;
    float: right;
    background: url('/Static/images/icons/svg/SIS_Ikon_Stang.svg') center no-repeat;
    border: none;
    width: 50px;
    height: 50px;
    margin: 0 10px 0;
    background-size: 35px 35px;

    &.collapsed {
        background-image: url('/Static/images/icons/svg/SIS_Ikon_Meny.svg');
        background-size: 35px 24px;
    }
}

.navbar-header-cart {
    margin-right: 10px;
    background: url('/Static/images/icons/svg/SIS_Ikon_Stang.svg') center no-repeat;
    width: 50px;
    height: 50px;
    background-size: 30px 30px;
    text-align: center;
    font-weight: 700;
    color: black;
    padding-left: 4px;
    padding-top: 4px;
    font-size: 0;
    position: relative;

    &.collapsed {
        background-size: 48px 48px;
        background-image: url(/Static/images/SIS_Ikon_Shopping.svg);

        &.filled-cart {
            background-image: url(/Static/images/SIS_Ikon_Shopping_has_items.svg);
            font-size: 18px;
        }

        &.empty-cart {
            background-image: url(/Static/images/SIS_Ikon_Shopping.svg);
        }
    }
}

.navbar-nav {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    > li > a {
        color: $text-color;
        text-transform: uppercase;
        border-bottom: solid 1px $brand-gray-45;
        padding: 10px 20px;
        line-height: $line-height-base;

        &:hover,
        &:focus {
            color: $brand-blue-75;
        }
    }

    > li.active {
        background-color: $brand-gray-15;
    }

    .has-mega-menu > a,
    .has-children > a {
        background: url('/Static/images/icons/svg/SIS_Ikon_Karusell_hoger.svg') right 15px center / 15px 15px no-repeat;
    }

    &.menu-clone {
        position: absolute;
        left: 100%;
        top: 0;

        .back-button {
            background: $brand-gray-15 url('/Static/images/icons/svg/SIS_Ikon_Karuesell_vanster.svg') left 15px center no-repeat;
            background-size: 15px 15px;
        }

        > li > a {
            padding-left: 50px;
        }

        ul li {
            display: none;
        }
    }

    > li.has-mega-menu.subscriptionUser, > li.active.subscriptionUser {
        background-color: $brand-red;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        &:hover {
            background-color: $brand-red-75;

            > a {
                @media (min-width: $screen-md-min) {
                    border-color: transparent;
                }
            }
        }

        > a {
            color: $white;
            background: url('/Static/images/icons/svg/SIS_Ikon_Karusell_hoger_white.svg') right 15px center / 15px 15px no-repeat $brand-red;

            &:hover {
                background-color: $brand-red-75;
            }
        }
    }
}

.mega-menu {
    display: none;
    max-width: 100vw;

    a {
        user-select: none;
        -webkit-user-select: none;
        padding: 10px;
        display: block;
        color: $text-color;

        &:hover,
        &:focus {
            text-decoration: none;
            background: $brand-gray-15;
        }
    }

    .image-teaser-text {
        width: 100%;
    }

    ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.has-mega-menu--mobile {
    order: -1;

    > a {
        background-color: $brand-gray-15;
    }
}

.mobile-login-menu {
    overflow: hidden;
    order: 2;

    a {
        display: block;
        padding: 14px 20px 9px;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        background: $brand-red;

        &:focus, &:active, &:hover {
            background: $brand-red-75;
        }
    }
}

.abo-picker {
    display: inline-block;
    font-size: 15px;
    background-color: $brand-beige-15;
    list-style-type: none;
    padding: 0;
    margin: 0 0 0 -5px;
    min-width: 300px;

    @media(max-width: $container-large-desktop) and (min-width: $screen-lg) {
        // Fix for very long subscription names in small screen sizes.
        max-width: 45%;

        &-link {
            &-text-wrapper {
                &-abo-text {
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    @media(max-width: $screen-sm-min) {
        flex: 2 !important;
        /*font-size: 12px;*/

        > a {
            padding: 10px 6px !important;
        }
    }

    @media (max-width: $screen-md-max) {
        margin-left: 0;

        > a {
            padding: 10px 20px;

            & span {
                display: none;
            }

            & strong::before {
                content: "SIS ";
            }
        }
    }


    &:hover .abo-picker-body {
        /*display: block;*/
    }

    a {
        color: $text-color;
        padding: 9px 20px;
        display: list-item;
    }

    .abo-picker-body {
        display: none;
        position: absolute;
        z-index: 99999;
        background-color: $brand-beige-15;
        border: 1px solid $brand-beige-25;
        border-top: none;
        list-style-type: none;
        padding: 10px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
        border-radius: 5px;

        .abo-picker-child {

            a[data-selected="True"] {
                background-color: $brand-blue-75;
                color: $white;
            }

            &:first-child {
                display: none;
            }

            &:last-child a {
                margin-bottom: 0;
            }

            a {
                padding: 10px 20px;
                border: 1px solid $brand-blue-75;
                border-radius: 5px;
                margin-bottom: 10px;
                display: list-item;
                background-color: transparent;
                transition: background-color 0.15s ease;

                @media (prefers-reduced-motion) {
                    transition: none;
                }

                user-select: none;
                -webkit-user-select: none;

                &:hover {
                    background-color: $brand-blue-75;
                    text-decoration: underline;
                    color: $white;
                }
            }
        }
    }
}

#my-sis-bar-desktop {
    @media(max-width: $screen-lg) {
        background: $brand-beige-15;
    }

    @media(max-width: 1250px) {
        background: $brand-beige-15;
    }

    @media(min-width: 1250px) {
        background: linear-gradient(to right, $brand-red calc((100% - 1250px)/2 + 10px),$brand-beige-15 calc((100% - 1250px)/2 + 10px));
    }

    border-bottom: 1px solid $brand-beige-25;
}

.competence-portal {
    @media(max-width: $screen-sm-min) {
        flex: 2 !important;
        padding: 10px 6px;
        /*font-size: 12px;*/
    }

    display: inline-block;
    font-size: 15px;
    background-color: $brand-beige-15;
    padding: 9px 20px;
    align-items: center;

    &-icon {
        height: 16px;
        width: 16px;
        margin-right: 4px;
    }

    > a {
        color: $text-color;
    }
}

#mysismenu {
    display: inline-block;
    font-size: 15px;
    position: relative;

    @media(max-width: $screen-sm-min) {
        flex: 1 !important;
        /*font-size: 12px;*/

        > a {
            padding: 10px 6px !important;
        }
    }

    > a {
        padding: 9px 20px;
        display: block;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;
        background-color: $brand-red;
        color: #FFF;
        user-select: none;
        -webkit-user-select: none;
    }

    @media (min-width: 769px) {
        &:hover > a {
            color: $text-color;
            background-color: $brand-gray-15;
            position: relative;
            z-index: 999999;
            border-right: 1px solid $brand-gray-45;
            border-left: 1px solid $brand-gray-45;
        }
    }

    &:hover #my-sis-pane {
        background-color: $brand-gray-15;
        border: 1px solid $brand-gray-45;
        margin-top: -1px;
    }
}

#my-sis-pane {
    display: none;
    position: absolute;
    z-index: 99999;
    background-color: $brand-gray-15;
    color: $text-color;
    padding: 20px;
    max-width: 450px;
    box-shadow: 5px 5px 8px rgba(0,0,0,0.05);
    min-width: 100%;

    ul {
        min-width: 300px;
        list-style: none;
        padding: 0;

        li {
            margin-bottom: 10px;
            user-select: none;
            -webkit-user-select: none;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    a {
        color: $text-color;
    }

    hr {
        border-color: $brand-gray-45;
        opacity: 1;
    }
}

.panel-my-sis {
    background: $brand-red;

    > .panel-heading {
        background: $brand-red;
        color: $white;
    }

    .btn-info {
        color: $white !important;
    }

    a {
        color: $link-color !important;
    }

    a.expand-area,
    a.contract-area {
        background: $brand-gray-25;
    }

    .list-group > .list-group-item {
        color: $text-color;
        background-color: $white;
        border: 1px solid $brand-gray-45 !important;
        padding: 10px 20px;
    }
}

a:hover .panel-my-sis > .panel-heading {
    text-decoration: none;
}

.my-sis-contactinformation {
    user-select: initial;
    -webkit-user-select: initial;
}

.navbar {
    border: none;
    @include clearfix();
    margin-bottom: 0;
}

.navbar-brand {
    margin: 15px 20px !important;
    padding: 0;
    height: auto;
    user-select: none;
    -webkit-user-select: none;
    flex: 1;

    img {
        width: 152px;
        height: 49px;
    }

    @media (min-width: $screen-sm-min) {
        margin: 20px 0 !important;

        img {
            width: 236px;
            height: 60px;
        }
    }
}

.quick-links {
    float: right;
    margin: 0;
    padding: 0;
    font-size: 12px;
    user-select: none;
    -webkit-user-select: none;

    .quick-link {
        float: left;
        list-style: none;
        margin: 0;
        text-transform: uppercase;
        display: block;
        padding: 10px 10px;
        color: $text-color-light;

        &:hover {
            text-decoration: none;
            background: $brand-gray-15;
        }

        @media (max-width: $screen-sm-min) {
            float: none;
        }

        &.quick-link--dark {
            background: $brand-red;
            color: #FFF;

            &:hover {
                background: $brand-red-75;
            }
        }
    }
}

.navbar-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: $screen-md-min) {
    .navbar-nav-wrapper {
        overflow: visible;
    }

    .menu-clone {
        display: none;
    }

    .navbar-nav {
        float: right;
        width: auto;
        position: relative;
        z-index: 99;
        font-size: 16px;
        display: block;
        margin-top: 22px;
        margin-right: 11px;

        > li > a {
            color: $text-color-light;
            border: solid 1px transparent;
            padding: 8px 13px 8px;

            &:hover,
            &:focus {
                color: $text-color;
                background: $brand-gray-15;
            }
        }

        .has-mega-menu > a,
        .has-children > a {
            background: none;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
}

@media (min-width: $screen-lg-min) {
    .has-mega-menu {
        position: static !important;
        z-index: 999;

        > a {
            z-index: 999;
            background: url('/Static/images/mega-menu-caret.png') right center no-repeat !important;
            padding-right: 28px !important;
        }
    }

    .no-js .has-mega-menu:hover > a,
    .no-js .has-mega-menu > a:hover,
    .has-mega-menu.hover > a, .has-mega-menu.hover, .has-mega-menu.hover > a:hover {
        background-color: $white;
        border-color: $brand-gray-75;
        border-bottom-color: $white;
    }

    .mega-menu {
        position: absolute;
        top: 41px;
        right: 0px;
        border: solid 1px $brand-gray-75;
        background: $white;
        padding: 20px !important;
        width: $container-lg;
        max-width: calc(100vw - 40px);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .no-js :hover > &,
        .hover > & {
            display: block;
        }

        .mega-menuimage {
            width: 285px;
            max-height: 198px;
        }


        li .has-mega-menu {
            &:hover {
                display: block;
            }
        }

        > ul {
            &.columns-2 {
                columns: 2;
            }

            &.columns-3 {
                columns: 3;
            }

            &.columns-4 {
                columns: 4;
            }

            &.columns-5 {
                columns: 5;
            }
        }

        &.has-teaser {
            > ul {
                margin-right: 285px;
            }
        }

        .teaser {
            width: 285px;
            float: right;
        }

        > ul {
            columns: 4;
            //column-fill: auto;

            li {
                break-inside: avoid;
            }

            ul {
                display: none;
            }
        }
    }

    .has-mega-menu--mobile {
        > a {
            background: none !important;
            padding-right: 9px !important;
            background-color: transparent;

            &:hover {
                background: $brand-gray-15;
            }
        }

        &.hover > a {
            border: 1px solid transparent;
            background-color: $brand-gray-15;
        }

        &.hover > .mega-menu, .no-js :hover > .mega-menu {
            display: none;
        }
    }

    .navbar-header {
        float: left;
        min-width: 200px; //IE fix
    }

    .navbar-collapse {
        width: auto;

        &.collapse {
            display: block !important;
            visibility: visible !important;
            height: auto !important;
            padding-bottom: 0;
            overflow: visible !important;
            clear: right;
        }
    }

    .navbar-nav > li {
        float: left;
    }
}


@media (max-width: $screen-md-max) {
    .navbar {
        display: flex;
        flex-direction: column;

        > .container-fluid {
            order: 1;
            width: 100%;
        }
    }

    .toolbox {
        order: 3;
    }

    #my-sis-bar-desktop {
        order: 2;
        background: none;
        border-bottom: 0;

        #my-sis-pane {
            left: 0;
        }

        .container-fluid {
            display: flex !important;
            flex-flow: row;
            padding: 0;
            margin: 0;
            width: unset;

            & > div, & > ul {
                flex: 1 100%;
                min-width: initial;

                & > span {
                    font-weight: bold;
                }
            }

            #mysismenu {
                > a {
                    padding: 10px 20px;

                    & span {
                        display: none;
                    }
                }

                #my-sis-pane {
                    padding: 0 0 40px;

                    > div {
                        padding: 0 20px;
                    }

                    ul li {
                        border-top: 1px solid $brand-gray-45;
                        padding: 12px 20px;
                        margin-bottom: 0;

                        &:last-child {
                            border-bottom: 1px solid $brand-gray-45;
                        }
                    }

                    hr {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md-max) {
    .navbar-toggle {
        display: block;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }

    .navbar-nav {
        float: none !important;

        > li {
            float: none;

            > a {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }

    .navbar-text {
        float: none;
        margin: 15px 0;
    }

    .collapsing {
        overflow: hidden !important;
    }
}
