﻿@import "_variables.scss";
@import "_mixins.scss";

.carousel {
    margin: 15px 0;

    .slick-prev,
    .slick-next {
        position: absolute;
        top: 33%;
        width: 30px;
        height: 30px;

        &:focus,
        &:active {
            outline: none;
            opacity: 0.5;
        }
    }

    .slick-prev {
        left: -5px;
        background: url('/Static/images/icons/svg/SIS_Ikon_Karuesell_vanster.svg') center no-repeat;
    }

    .slick-next {
        right: -5px;
        background: url('/Static/images/icons/svg/SIS_Ikon_Karusell_hoger.svg') center no-repeat;
    }

    .slick-list {
        margin-left: 30px;
        margin-right: 30px;
    }

    .carousel-item {
        text-align: center;
        text-decoration: none;
        color: $text-color;
        margin: 20px;

        &:focus,
        &:active {
            outline: none;
            text-decoration: none;
            color: $text-color;
        }
    }

    .carousel-image {
        display: block;

        img {
            max-width: 100%;
            height: auto;
        }

        &::before {
            content: "";
            width: 120px;
            height: 200px;
            box-shadow: 5px 5px 8px rgba(0,0,0,.05);
        }
    }
}