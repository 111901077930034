﻿@import "_variables.scss";
@import "_mixins.scss";

.footer {
    background: $brand-gray-15;
    background-size: cover;
    padding: 40px 10px 20px 10px;
    margin-top: 40px;

    a {
        color: $brand-blue;
    }
}

.footer-follow-links {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;

    .footer-follow-link {
        margin-left: 16px;
        width: 36px;
        height: 36px;
        border-radius: 5px;

        &.twitter {
            background: url('/Static/images/twitter.svg');
        }

        &.facebook {
            background: url('/Static/images/facebook_social_icon.svg');
            background-size: 36px 36px;
        }

        &.linkedin {
            background: url('/Static/images/linked_in.svg');
        }

        &.instagram {
            background: url('/Static/images/instagram.svg');
            background-size: 36px 36px;
            background-repeat: no-repeat;
        }

        &.youtube {
            background: url('/Static/images/youtube_social_icon.svg') #ff0000 no-repeat center center/30px 30px;
            border-radius: 3px;
        }
    }
}
.footer-quick-links {
    background: $brand-red;

    a {
        line-height: 50px;
        color: $white;
        margin-right: 10px;
    }

    .footer-report {
        display: inline-block;
        float: right;

        @media (max-width: $screen-sm-min) {
            float: left;
        }

        a {
            color: $white;
            margin-right: 0;
        }
    }
}
