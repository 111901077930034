﻿.onlineservice-export-pdf {
    font-family: Arial !important;

    &-heading-1 {
        font-size: 18pt !important;
    }

    &-heading-2 {
        font-size: 16pt !important;
    }

    table, p, img, h1, h2, h3, h4, h5 {
        page-break-inside: avoid;
    }

    a {
        text-decoration: none !important;
    }

    &-coverpage {
        margin-top: 1.75cm; // Extra margin top on cover page
        margin-right: 1.75cm;
        margin-left: 4.23cm;
        page-break-after: always;

        &-user-text {
            font-size: 12pt;
            padding: 20px;
            margin-bottom: 40px;

            &--small {
                font-size: 10pt;
            }
        }

        ul {
            padding-left: 40px !important; // Needed since we have global rules for ul's in article in main.scss

            li:before {
                color: #000 !important; // Needed since we have global rules for li's in article in main.scss
            }
        }
    }

    &-content {
        margin-top: 1.75cm; // Extra margin top on first page of content
        margin-right: 1.75cm;
        margin-left: 4.23cm;

        h1, h2, h3 {
            page-break-inside: avoid;
        }

        h1::after,
        h2::after,
        h3::after {
            content: "";
            display: block;
            height: 100px;
            margin-bottom: -100px;
        }

        .break-before {
            page-break-before: always;
        }
    }
}
