﻿@import "_variables.scss";
@import "_mixins.scss";

/* SHOPPING CART */
.shopping-cart {
    background: $brand-gray-25;
    overflow: hidden;
    border-top: solid 1px $brand-gray-75;
    border-bottom: solid 1px $brand-gray-75;

    &.collapse{
        display:none;
        &.show{
            display:block;
        }
    }
}

.shopping-cart-heading {
    margin-top: 20px !important;
    line-height: 45px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}

.delete-shopping-cart-item,
.delete-checkout-item, .delete-category-filter {
    width: 30px;
    height: 30px;
    background: url('/Static/images/icons/svg/SIS_Ikon_Stang.svg') center center no-repeat;
    border: 1px solid $alert-danger-border;
    border-radius: 15px;
    background-size: 13px 13px;

    &:active, &:hover, &:focus {
        border: 1px solid $brand-red-45;
        border-radius: 15px;
        background: url('/Static/images/icons/svg/SIS_Ikon_Stang.svg') center center no-repeat;
        background-size: 13px 13px;
    }
}

.totals-cell {
    font-weight: bold;
    border-top: solid 2px $brand-gray-75 !important;
}

.shopping-cart-item {
    clear: both;
    border-bottom: solid 1px $brand-gray-75;
    margin-bottom: 20px;
}

.shopping-cart-message {
    padding: 20px;
    border: solid 2px $brand-red;
    background-color: $white;

    .list-group:last-child {
        margin-bottom: 0;
    }

    .list-group-item {
        border: none;
        background: none;

        & + .list-group-item {
            border-top: solid 1px $brand-gray-75;
        }
    }
    p:last-child{
        margin-bottom:0;
    }
}

.shopping-cart-close {
    float: right;
    font-size: 18px;
    color: $text-color;
    text-decoration: underline;
    margin-top: 20px;

    @media (min-width: $screen-md-min) {
        margin-top: -25px;
    }

    .shopping-cart-heading & {
        margin-top: 0;
        font-weight: normal;
        text-transform: none;
    }
}