﻿@import "_variables.scss";
@import "_mixins.scss";

/* ThemePage - Remove panel styling */
.themePage {
    > .panel, > .panel-primary, > .panel-gray {
        max-width: 676px;

        .panel-heading {
            background: none;
            padding: 0;
            border: 0;

            .panel-title {
                font-size: $font-size-h1;
                margin-bottom: .5em;
            }
        }

        .panel-body {
            background: none;
            padding: 0;
            font-size: $font-size-base;
            line-height: $line-height-base;
        }
    }
}

/* SHARE ICONS */
.themePage .share-icons {
    margin-top: 2px;
}

@media (min-width: $screen-sm-min) {
    .themePage .productlistblock {
        margin-top: 40px;
    }
}

.themePage .listblock .col-sm-6 {
    padding-left: 0;
}

.themePage .listblock {
    margin-top: 40px;
}