﻿@import "_variables.scss";
@import "_mixins.scss";

.nav-tabs {
    margin-top: 30px;
    background-color: $brand-gray-15;
    border-bottom: 1px solid $brand-gray-45;

    .nav-item {

        .nav-link {
            color: $text-color;
            font-size: 16px;
            padding: 11px 20px 7px;
            border: 0 !important;
            margin: 0;

            &:hover {
                background: $brand-gray-45;
            }

            &.active {
                color: $white;
                background: $brand-blue;
                font-weight: bold;
                pointer-events: none;
            }
        }



        &::before {
            display: none;
        }
    }
}
