﻿@import "_variables.scss";

@mixin button-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);
    }

    &:active,
    &.active,
    .open > .dropdown-toggle {
        background-image: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] {
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}

@mixin btn-rounded {
    padding: 10px 15px;
    border-radius: 5px;
    line-height: 1em;

    &.btn-sm {
        padding: 8px 10px;
    }

    @media (min-width: $screen-sm-min) {
        padding: 10px 30px 10px;

        &.btn-sm {
            padding: 10px 15px;
        }
    }
}

@mixin module-margin {
    margin-bottom: $module-margin-xs;

    @media (min-width: $screen-sm-min) {
        margin-bottom: 20px;
    }

    @media (min-width: $screen-md-min) {
        margin-bottom: $module-margin-lg;
    }
}

@mixin module-margin-top {
    margin-top: $module-margin-xs;

    @media (min-width: $screen-sm-min) {
        margin-top: $module-margin-sm;
    }

    @media (min-width: $screen-md-min) {
        margin-top: $module-margin-md;
    }
}

@mixin module-margin-xs {
    margin-bottom: $module-margin-xs;

    @media (min-width: $screen-sm-min) {
        margin-bottom: 0;
    }
}

@mixin clearfix {
    &:before {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}