﻿@import "_variables.scss";
@import "_mixins.scss";

.online-service {
    &-page-navigator {
        &-buttons {
            a {
                word-break: keep-all;
            }
        }
    }

    &-mainbody {
        .image {
            display: inline-block;
            position: relative;
        }
    }
}

// TODO: Move this globally
[v-cloak] {
    display: none;
}

#onlineServiceSearch {
    margin-top: -10px;
    background: white;
    z-index: 1;
    margin-bottom: 20px;

    @media (min-width:$screen-md-min) {
        margin-top: -30px;
    }

    @media (min-width:$screen-lg-min) {
        position: absolute;
        right: 10px;
    }

    .onlineservice-search-tools {
        display: flex;
        position: relative;

        span {
            flex: 1;

            input {
                border: solid 1px $brand-gray-75;
                padding: 5px 5px 5px 30px;
                font-size: 16px;
            }

            &:before {
                content: "";
                position: absolute;
                background: url(/Static/images/icons/svg/SIS_Ikon_Sok.svg) no-repeat;
                width: 16px;
                height: 16px;
                top: 12px;
                left: 10px;
            }
        }

        button {
            background: $brand-red;
            padding: 7px 15px;
            border-radius: 0;
            color: #FFF;
            border-left: 0;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 1em;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;

            &:hover, &:focus {
                color: #FFF;
                opacity: 0.8;
            }
        }
    }
}

.search-loading {
    position: absolute;
    right: 25px;
    top: 10px;

    img {
        padding: 0 !important;
        margin: 0 !important;
    }
}

#searchresult {
    /* min-width: calc(100vw - 20px);
    max-width: calc(100vw - 20px);*/

    @media (min-width:$screen-md-min) {
        max-width: 900px;
        min-width: 710px;
    }

    position: absolute;
    z-index: 1;
    background: #FFF;
    border: solid 1px $brand-gray-75;
    box-shadow: 0 10px 5px rgba(0,0,0,.175);

    .searchresult-information {
        padding: $grid-gutter-width;
    }

    ol, li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        em {
            font-style: normal;
            font-weight: bold;
        }

        p {
            margin: 0;
        }

        a {
            display: block;
            padding: 20px 20px;
            border-top: solid 1px $brand-gray-45;
            color: $black;

            &:hover {
                text-decoration: none;
                background: $brand-gray-25;
            }
        }

        h2 {
            font-size: 18px;
        }
    }

    .crumb {
        font-size: 12px;

        span {
            margin-right: 2px;

            &:before {
                margin-right: 2px;
                content: '/';
                position: relative;
            }
        }
    }
}

.page-selector {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        padding: 0;

        ul {
            padding-left: 15px;
        }

        input[type=checkbox] {
        }
    }

    .has-children {
        > ul {
            display: none;
        }
    }


    li ul li {
        > ul {
            display: none;
        }
    }

    .expanded {
        > label > .menu-toggle {
            transform: rotate(180deg);
        }

        > ul {
            display: block;
        }
    }


    .menu-toggle {
        border: none;
        background: url('/static/images/icons/svg/SIS_Ikon_Caret.svg') no-repeat center;
        background-size: 60%;
        width: 16px;
        height: 16px;
        padding: 10px;
        opacity: 0.25;
        transition: all ease .2s;
        margin-left: 5px;

        &:hover {
            opacity: 1;
        }
    }
}

.onlineservice-export-form {
    background: $brand-gray-15;
    border-radius: 10px;
    padding: 2rem;

    h2 {
        font-size: 20px;
    }

    .export-button,
    .abort-button {
        min-width: 120px;

        &:disabled {
            opacity: 0.25;
        }
    }
}
